export default [
  {
    path: '/message',
    name: 'message',
    redirect: '/message/list',
    component: () => import(/* webpackChunkName: "message" */ '@/views/message/index.vue'),
    meta: {
      title: '消息'
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        name: 'nomessage-list',
        component: () => import(/* webpackChunkName: "message-list" */ '@/views/message/list.vue'),
        meta: {
          id: '1',
          title: '消息',
          module: 'message',
          pid: '0'
        }
      }
    ]
  }
]
