export default [
  {
    path: '/customer2',
    name: 'customer2',
    redirect: '/customer2/channel',
    component: () => import(/* webpackChunkName: "index" */ '@/views/team/index.vue'),
    meta: {
      title: '自定义维度2-test1'
    },
    children: [
      {
        path: 'channel',
        component: () => import('@/views/team/channel'),
        name: 'customer2-channel',
        meta: {
          id: '1',
          title: '自定义维度2-test2',
          module: 'customer2',
          pid: '0'
        },
        props: route => ({
          code: route.query.code,
          name: route.query.name
        })
      },
      {
        path: 'initiate',
        component: () => import('@/views/team/initiate'),
        name: 'customer2-initiate',
        meta: {
          id: '1_1',
          title: '我发起的',
          module: 'customer2',
          pid: '1'
        },
        props: route => ({ moduleName: route.query.moduleName || 'all' })
      },
      {
        path: 'initiateDetail',
        component: () => import('@/views/team/customDetail'),
        name: 'customer2-initiateDetail',
        meta: {
          id: '1_1_1',
          title: '自定义维度2提案审批',
          module: 'customer2',
          pid: '1_1'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode,
          moduleName: route.query.moduleName || 'all'
        })
      },
      {
        path: 'initiateProposal',
        component: () => import('@/views/team/addProposalCustomer'),
        name: 'customer2-initiateProposal',
        meta: {
          id: '1_2',
          title: '发起提案',
          module: 'customer2',
          pid: '1'
        },
        props: route => ({
          type: route.query.type,
          id: route.query.id,
          moduleName: route.query.moduleName || 'all'
        })
      },
      {
        path: 'approval',
        component: () => import('@/views/team/approval'),
        name: 'customer2-approval',
        meta: {
          id: '1_3',
          title: '我审批的',
          module: 'customer2',
          pid: '1'
        },
        props: route => ({ moduleName: route.query.moduleName || 'approval' })
      },
      {
        path: 'approvalDetail',
        component: () => import('@/views/team/customDetail'),
        name: 'customer2-approvalDetail',
        meta: {
          id: '1_3_1',
          title: '自定义维度2提案审批',
          module: 'customer2',
          pid: '1_3'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode,
          moduleName: route.query.moduleName || 'approval'
        })
      },
      {
        path: 'all',
        component: () => import('@/views/team/customDetail'),
        name: 'customer2-all',
        meta: {
          id: '1_4',
          title: '自定义维度2详情',
          module: 'customer2',
          pid: '1'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode
        })
      }
    ]
  }
]
