import { medal } from '@/config/config.js'
export const personList = state => state.personList
export const userId = state =>state.userInfo.userId
export const userName = state =>state.userInfo.userName
export const avatar = state => state.userInfo.avatar
export const medalGrade = state => {
  let medalGrade = state.userInfo.medalGrade
  if(medalGrade && medalGrade.length > 0) {
    medal.forEach(element => {
      medalGrade.forEach(e => {
        if (element.medalType === e.medalType) {
          e.icon = element.icon
          e.color = element.color
        }
      })
    })
  }
  return medalGrade
}
