<template>
  <div class="container">
    <div class="container-left">
      <div class="userinfo">
        <div v-if="avatar" class="avatar"><img :src="avatar ? avatar : defaultAvatar" @click="goUrl('/personalCenter/material')" /></div>
        <div v-else class="avatar avatardefault" @click="goUrl('/personalCenter/material')">{{ avatarName }}</div>
        <div class="userinfohover">
          <div class="avatar" v-if="avatar"><img :src="avatar ? avatar : defaultAvatar" @click="goUrl('/personalCenter/material')" /></div>
          <div class="avatar avatardefault" @click="goUrl('/personalCenter/material')" v-else>{{ avatarName }}</div>
          <div class="userName">{{ userName }}</div>
          <div class="medalGrade" style="margin-bottom:20px">
            <div v-for="(item, index) in medalGrade" :key="index" class="icon">
              <img :src="item.icon" />
              <span :style="{ color: item.color }">{{ item.medalNum | filterMedalNum}}</span>
            </div>
          </div>
          <p>{{ jobName }}</p>
          <p>{{ orgChain }}</p>
        </div>
      </div>
      <ul class="navlist">
        <li v-for="(item, index) in leftnav" :key="index" @click="goUrl(item.path)" :class="{ on: $route.path === item.path }">
          <span class="iconfont" :class="item.icon">
            <i class="hongdian" v-if="backlogCount > 0 && item.path === '/message/list'"></i>
          </span>
          <span class="title">{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <div class="container-right">
      <div class="header">
        <div class="header-left" @click="goUrl('/')">
          <img src="@/assets/images/logo-black.png" width="99px" />
          <span>|</span>
          <p class="companyname">{{ companyName }}</p>
        </div>
        <ul class="header-right">
          <li v-for="(item, index) in topnav" :key="index" @click="goUrl(item.path)">
            <span class="iconfont" :class="item.icon"></span>
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="content-container">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
    <!-- 修改密码弹框 -->
    <el-dialog title="修改密码" :visible.sync="editPasswordDialog" width="400px">
      <el-form :model="editPasswordForm" :rules="rules" ref="editPasswordForm" label-width="70px">
        <el-form-item label="新密码" prop="password">
          <el-input type="password" v-model="editPasswordForm.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="editPasswordForm.checkPass"></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button @click="editPasswordDialog = false">取消</el-button>
          <el-button type="primary" @click="submitEditPassword">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { leftnav, topnav } from '@/config/config.js'
import security from '@/utils/security.min'
import { passwdReg } from '@/utils/index.js'
import { logout, editPassword, secret } from '@/api/api_public'
import { mapState, mapGetters } from 'vuex'
import { MessageBox } from 'element-ui'
const defaultAvatar = require('@/assets/images/profile.png')
export default {
  name: 'home',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (!passwdReg.test(value)) {
        callback(new Error('6-16个字符，包含字母、数字、标点任意2种组合'))
      } else {
        if (this.editPasswordForm.checkPass !== '') {
          this.$refs.editPasswordForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editPasswordForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      defaultAvatar,
      leftnav,
      topnav,
      editPasswordDialog: false,
      editPasswordForm: {
        password: '',
        checkPass: ''
      },
      rules: {
        password: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getUserAndNavInfo()
    this.getUserInfo()
    this.getMsgCount()
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      orgChain: state => (state.userInfo.jobList && state.userInfo.jobList.length > 0 ? state.userInfo.jobList[0].orgChain : ''),
      jobName: state => (state.userInfo.jobList && state.userInfo.jobList.length > 0 ? state.userInfo.jobList[0].jobName : ''),
      backlogCount: state => state.msgNumber.backlogCount,
      companyName: state => state.userInfo.companyName,
      avatarName: state => (state.userInfo.userName ? state.userInfo.userName.slice(-2) : '')
    }),
    ...mapGetters({
      medalGrade: 'medalGrade'
    })
  },
  methods: {
    getMsgCount() {
      this.$store.dispatch('getMsgCount')
    },
    getUserInfo() {
      this.$store.dispatch('getUserInfo')
    },
    goUrl(path) {
      if (path === 'loginOut') {
        this.loginOut()
      } else if (path === 'editPassword') {
        this.editPassword()
      } else {
        this.$router.push({ path })
      }
    },
    async getUserAndNavInfo() {
      let token = this.$route.query.token || localStorage.token
      if (token) {
        localStorage.setItem('token', token)
      } else {
        this.Login()
      }
    },
    loginOut() {
      MessageBox.confirm('确定要退出登录吗?', '提示', {
        confirmButtonClass: 'el-button--warning'
      }).then(() => {
        logout().then(result => {
          if (result && result.code === '000000') {
            this.$router.push({ path: '/login' })
          }
        })
      })
    },
    //
    getSecret() {
      secret().then(res => {
        if (res && res.code === '000000') {
          this.secret = res.data
        }
      })
    },
    //修改密码
    editPassword() {
      if (this.$refs.editPasswordForm != undefined) {
        this.$refs.editPasswordForm.resetFields()
      }
      this.getSecret()
      this.editPasswordDialog = true
    },
    submitEditPassword() {
      this.$refs.editPasswordForm.validate(valid => {
        if (valid) {
          let params = {
            password: this.editPasswordForm.password
          }
          params.password = security.encrypty(this.secret.modulu, this.secret.exponent, params.password)
          editPassword(params).then(res => {
            if (res && res.code === '000000') {
              this.$msgSuccess('修改成功')
              this.editPasswordDialog = false
            } else {
              this.$msgError(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  height: 100%;
  background: #f5f5f5;
  position: relative;
  top: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  min-width: 1200px;
  &-left {
    width: 60px;
    height: 100%;
    background: linear-gradient(180deg, #505e7e 0%, #293449 100%);
    .userinfo {
      padding: 20px 0;
      position: relative;
      z-index: 10;
      text-align: center;
      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 2px solid #fff;
        margin: 0 auto;
        background: #fff;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .avatardefault {
        background: $blue;
        line-height: 32px;
        color: #fff;
        font-size: 12px;
      }
      .userinfohover {
        display: none;
        width: 280px;
        height: 280px;
        padding: 30px 20px;
        background: url('../assets/images/personbg.png') no-repeat;
        background-size: 280px 280px;
        position: absolute;
        left: 45px;
        top: 45px;
        z-index: 11;
        border-radius: 2px;
        color: #fff;
        .avatar {
          width: 54px;
          height: 54px;
        }
        .avatardefault {
          line-height: 50px;
          font-size: 18px;
        }
        .userName {
          font-size: 18px;
          line-height: 24px;
          font-weight: bold;
          margin: 10px 0;
        }
        p {
          font-size: 12px;
          line-height: 16px;
          margin-top: 5px;
        }
      }
      &:hover {
        .userinfohover {
          display: block;
        }
      }
    }
    .navlist {
      width: 100%;
      li {
        height: 46px;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
        position: relative;
        z-index: 9;
        .iconfont {
          font-size: 20px;
          color: #949bae;
          .hongdian {
            display: block;
            width: 8px;
            height: 8px;
            background: $red;
            border-radius: 50%;
            position: absolute;
            right: 16px;
            top: 10px;
          }
        }
        .title {
          display: none;
          width: 48px;
          height: 34px;
          line-height: 34px;
          background: #23282f;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
          border-radius: 3px;
          position: absolute;
          right: -40px;
          top: 50%;
          margin-top: -17px;
          color: #fff;
          font-size: 12px;
        }
        &.on {
          background: $blue;
          .iconfont {
            color: #fff;
          }
        }
        &:hover {
          .iconfont {
            color: #fff;
          }
          .title {
            display: block;
          }
        }
      }
    }
  }
  &-right {
    width: calc(100% - 60px);
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .header {
      height: 64px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      background: $white;
      display: flex;
      justify-content: space-between;
      &-left {
        display: flex;
        height: 100%;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;
        span {
          margin: 0 20px;
          color: $linecolor;
        }
        .companyname {
          font-size: 14px;
          color: $lightblack;
        }
        img {
          cursor: pointer;
        }
      }
      &-right {
        display: flex;
        height: 100%;
        align-items: center;
        li {
          margin-right: 40px;
          font-size: 14px;
          color: $darkgrey;
          cursor: pointer;
          .iconfont {
            font-size: 14px;
          }
          &:hover {
            color: $blue;
          }
        }
      }
    }
    .content-container {
      height: calc(100% - 64px);
      flex: 1;
      overflow: auto;
    }
  }
}
</style>
