import Vue from 'vue'
import VueRouter from 'vue-router'
import routeBehaviour from './behaviour'
import routeDemocracy from './democracy'
import routeSatisfaction from './satisfaction'
import routeInitiate from './initiate'
import routeTeam from './team'
import routeCustomer from './customer'
import routeCustomer2 from './customer2'
import routeOrganizationa from './organizational'
import routeThrclear from './thrclear'
import myTarget from './mytarget'
import routepersonalCenter from './personalCenter'
import routehonmepage from './honmepage'
import routeMessage from './message'
import Home from '../views/Home.vue'
import WrokDay from '../views/thrclear/work-day.vue'
import Organ from '../views/organizational/organizeEvaluation.vue'
Vue.use(VueRouter)

const config = [
  ...routeBehaviour,
  ...routeDemocracy,
  ...routeInitiate,
  ...routeThrclear,
  ...myTarget,
  ...routeOrganizationa,
  ...routeSatisfaction,
  ...routepersonalCenter,
  ...routehonmepage,
  ...routeMessage,
  ...routeTeam,
  ...routeCustomer,
  ...routeCustomer2
]
// 解决路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/dashboard',
    meta: {
      title: '工作台'
    },
    children: [
      {
        path: '/dashboard',
        component: () => import('../views/Dashboard.vue'),
        name: '工作台',
        meta: {
          title: '工作台'
        }
      }
    ].concat(config)
  },
  // {
  //   ...routeInitiate
  // },
  {
    path: '/Organ',
    name: 'Organ',
    component: Organ
  },
  {
    path: '/WrokDay',
    name: 'WrokDay',
    component: WrokDay
  },
  {
    path: '*',
    redirect: '/dashboard'
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/error404',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "error" */ '@/views/404.vue'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/error500',
    name: 'Error500',
    component: () => import(/* webpackChunkName: "error" */ '@/views/500.vue'),
    meta: {
      title: '服务器异常'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('🚀 ~ file: index.js:99 ~ router.beforeEach ~ from:', from)
  // console.log(to.meta.title)
  // if (to.name === from.name) {
  //   next(false)
  //   return false
  // }
  if (to.meta.title) {
    document.title = '幸福绩效-' + to.meta.title
  }
  localStorage.setItem('current-module', to.meta?.module)
  let _from = {
    module: from?.meta?.module || '',
    query: from?.query || {},
    name: from?.name || ''
  }
  sessionStorage.setItem('current-route-from', _from.module ? JSON.stringify(_from) : null)
  next()
})

router.onError(error => {
  console.log('router-error-msg:', error)
  const pattern = /Loading chunk (.)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  const targetPath = router.history.current.fullPath
  console.log(isChunkLoadFailed, targetPath)
  if (isChunkLoadFailed) {
    // router.replace(targetPath);
    location.reload()
  }
})

export default router
