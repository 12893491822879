import * as types from './mutation-types'

const mutations = {
  [types.SET_PERSON_LIST](state, data) {
    state.personList = data
  },
  [types.SET_USER_INFO](state, data) {
    state.userInfo = data
  },
  [types.SET_MSG_NUMBER](state, data) {
    state.msgNumber = data
  },
  [types.SET_TEAM_INFO](state, data) {
    state.teamInfo = data
  },
  [types.SET_AFFIRM_LIST_BY_MONTH](state, data){
    state.affirmListByMonth = data
  }
}

export default mutations
