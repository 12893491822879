import Vue from 'vue'

/**
 * 过滤-例子
 */
Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
/**
 * 202012->2020年12月
 */
Vue.filter('formateDate', function(year) {
  let y = String(year)
  let Y = y.slice(0, 4)
  let m = y.slice(y.length - 2, y.length)
  return `${Y}年${m}月`
})
/**
 * 勋章
 */
 Vue.filter('filterMedalNum', function(num) {
  return num > 9 ? '∞' : num
})