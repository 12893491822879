import * as http from './http'
// APP-工作三清列表包含点赞与评论[宋红飞]
export const list_reportall = (params, options) => http.post('/app/report-info/list-reportall', params, options)
// APP-三清评论[宋红飞]
export const comment = params => http.post('/app/report-info/comment', params, { loading: false })

// APP-三清审阅
export const comReview = params => http.post('/app/report-info/comment/review', params, { loading: false })

//审阅撤回
export const reviewDel = params => http.post('/app/report-info/review/del', params, { loading: false })

//三清批量审阅列表
export const listCommentBatch = params => http.post('/app/report-info/listReport/comment/batch', params, { loading: false })

//三清批量审阅
export const saveCommentBatch = params => http.post('/app/report-info/comment/batch', params, { loading: false })

//查询审阅配置
export const configQuery = params => http.post('/reviewConfig/query', params, { loading: false })

// APP-三清草稿保存-提交[宋红飞]
export const save = params => http.post('/app/report-info/save', params, { interceptRes: false })
// APP-三清点赞[宋红飞]
export const praise = params => http.post('/app/report-info/praise', params, { loading: false })
// 取消关注[郭红伟]
export const cancel = params => http.post('/attention/cancel', params, { loading: false })
// 关注[郭红伟]
export const attentionsave = params => http.post('/attention/save', params, { loading: false })
// APP-当日三清直属下级未交人员统计[宋红飞]
export const reportNextlevelundo = (params, options) => http.post('/app/report-info/report-nextlevelundo', params, options)
// APP-日历三清异常提示[宋红飞]
export const reportCalendar = (params, options) => http.post('/app/report-info/report-calendar', params, options)
// APP-查询所有会议类型[宋红飞]
export const listmeetypes = params => http.post('/app/meet/listmeetypes', params)
// APP-会议提交保存[宋红飞]
export const savemeetinfo = params => http.post('/app/meet/savemeetinfo', params)
// 上传文件、图片接口[任晓军]
export const upload = params => http.post('/my/file/upload', params, { loading: false })
// APP-查询会议记录列表[宋红飞]
export const listmeetinfo = (params, options) => http.post('/app/meet/listmeetinfo', params, options)
// APP-会议日历异常提示[宋红飞]
export const meetcalendartips = params => http.post('/app/meet/meetcalendartips', params)
// APP-查询会议决议列表[宋红飞]
export const listmeetresolution = params => http.post('/app/meet/listmeetresolution', params)
// APP-查询会议草稿[宋红飞]
export const meetdraftinfo = params => http.post('/app/meet/meetdraftinfo', params)
// APP-一键提醒提交三清[宋红飞]
export const reportRemind = params => http.post('/app/report-info/report-remind', params)
// APP-三清草稿回显[宋红飞]
export const reportDraftDetail = params => http.post('/app/report-info/report-draft-detail', params)
// APP-三清草稿回显[宋红飞]
export const updateresolutionState = params => http.post('/app/meet/updateresolution-state', params)
// APP-同步上次计划【宋红飞】
export const synchroplan = params => http.post('/app/report-info/report-synchroplan', params, { loading: false })
// APP-获取三清两会表单动态配置【宋红飞】
export const formConfig = params => http.post('/app/rpmt/rpmt/formConfig', params)
// APP-工作三清tab红点提示【宋红飞】
export const reportRedTips = params => http.post('/app/report-info/reportRedTips', params)
// APP-新的会议红点提示【宋红飞】
export const meetRedtips = params => http.post('/app/meet/meet-redtips', params)
// APP-三清评论删除【宋红飞】
export const deleteComment = params => http.post('/app/report-info/delete-comment', params, { loading: false })
// 当日三清是否需要填写与是否已提交【宋红飞】
export const fetchReportCheck = params => http.post('/app/report-info/report-check', params, { loading: false })
// APP-工作三清tab红点提示消除【宋红飞】
export const delReportRedTips = params => http.post('/app/report-info/delReportRedTips', params, { loading: false })
// APP-会议tab红点提示消除【宋红飞】
export const meetDelredtips = params => http.post('/app/meet/meet-delredtips', params, { loading: false })
// APP-三清补交点击校验【宋红飞】
export const reportRepairCheck = params => http.post('/app/report-info/reportRepairCheck', params)
// APP-三清补交点击校验【宋红飞】
export const repairSave = params => http.post('/app/report-info/repair-save', params)
// 提交月清 查看行为规范自评
export const submitState = params => http.post('/deduction/action/score/query/submitState', params)

// 查询员工行为规范扣分项配置
export const ruleQuery = params => http.post('/deduction/action/score/rule/query', params)
//查询月度自评的详情
export const selfEvaDetail = params => http.post('/deduction/action/score/query/detail', params)
