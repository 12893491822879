import { mineEvaluateList, getWaitList } from '@/api/api_democracy'
import { democracyNav } from '@/config/nav'
import { MineTabNames, UnderlingsTabNames } from '@/components/democracy/state.js'
const moduleDemocracy = {
  state: () => ({
    mineTabs: [],
    teamTabs: []
  }),

  mutations: {
    setMineTabs(state, data) {
      state.mineTabs = data
    },
    setApproveTabs(state, data) {
      state.teamTabs = data
    }
  },

  actions: {
    //待我评价页面红点
    async loadMineRed({ commit }) {
      let _tabs = MineTabNames
      const { code, data } = await mineEvaluateList()
      if (code === '000000' && data) {
        // .map(ite => ite.list)
        let dataList = data.filter(item => item.started)
        // console.log(dataList, 'ddd')
        const _redData = {
          comment: dataList?.length ?? 0
        }
        // console.log(_redData, '_redData???/')
        _tabs = MineTabNames.map(tab => {
          tab.badge = _redData[tab.name] ?? 0
          return tab
        })
      }
      commit('setMineTabs', _tabs)
    },

    //待我审批页面红点
    async loadApproveRed({ commit }) {
      let _tabs = UnderlingsTabNames
      const { code, data } = await getWaitList()
      if (code === '000000') {
        const _redData = {
          approve: data.total ?? 0
        }
        _tabs = UnderlingsTabNames.map(tab => {
          tab.badge = _redData[tab.name] ?? 0
          return tab
        })
      }
      commit('setApproveTabs', _tabs)
    }
  },

  getters: {
    navs(state) {
      democracyNav.children[0].badge = state.mineTabs
        .map(m => m.badge ?? 0)
        .reduce((total, cur) => {
          return total + cur
        }, 0)
      democracyNav.children[1].badge = state.teamTabs
        .map(m => m.badge ?? 0)
        .reduce((total, cur) => {
          return total + cur
        }, 0)

      return democracyNav.children
    }
  }
}

export default moduleDemocracy
