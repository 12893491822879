// const medalIcon01 = require('@/assets/images/xzicon01.png')
// const medalIcon02 = require('@/assets/images/xzicon02.png')
// const medalIcon03 = require('@/assets/images/xzicon03.png')
// const medalIcon04 = require('@/assets/images/xzicon04.png')
// const medalIcon05 = require('@/assets/images/xzicon06.png')

/**
 * 数据转换
 * @param t  string | <undefined>
 * @param obj Object
 * @returns Array | Object | any
 * @description t不传则返回入参对象 传入'list'则返回该对象的列表形式 传入key则返回在入参对象中对应的值
 */
export function transData(t, obj, obj2) {
  if (t === 'list') {
    let options = []
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const item = obj[key]
        if (isNaN(+key)) {
          options.push({ name: item, id: key })
        } else {
          options.push({ name: item, id: +key })
        }
      }
    }
    return options
  } else if (t === undefined) {
    if (obj2) {
      obj = { ...obj, ...obj2 }
    }
    return obj
  }
  return obj[t] || '-'
}

export const reportStatus = t =>
  transData(t, {
    1: '日评',
    2: '周评',
    3: '旬评',
    4: '月评'
  })

/**
 * 首页-左侧菜单
 */
export const leftnav = [
  {
    name: '首页',
    icon: 'iconhome',
    path: '/dashboard'
  },
  {
    name: '待办',
    icon: 'iconnodone',
    path: '/message/list'
  }
]
/**
 * 首页-顶部菜单
 */
export const topnav = [
  {
    name: '个人中心',
    icon: 'icongrzx',
    path: '/personalCenter'
  },
  {
    name: '意见反馈',
    icon: 'iconyjfk',
    path: '/personalCenter/opinion'
  },
  /* {
    name: '设置',
    icon: '',
    path: '/personalCenter/settings'
  }, */
  {
    name: '修改密码',
    icon: 'iconxiugaimima',
    path: 'editPassword'
  },
  {
    name: '退出',
    icon: 'iconloginout',
    path: 'loginOut'
  }
]
/**
 * 首页-常用应用
 */
export const homeusedapp = [
  {
    scen: ['home', 'thrClear'],
    name: '写日清',
    icon: 'iconfont iconblue',
    cls: 'dateColor',
    path: '/thrclear/writeday?reportType=1&name=写日清',
    reportType: '1',
    icons: 'iconxrq',
    class: 'iconbg-yellow',
    btnType: 'primary'
  },
  {
    scen: ['home', 'thrClear'],
    name: '写周清',
    icon: 'a iconfont iconblue',
    cls: 'weekColor',
    path: '/thrclear/writeweek?reportType=2&name=写周清',
    reportType: '2',
    icons: 'iconxzq',
    class: 'iconbg-blue',
    btnType: 'success'
  },
  {
    scen: ['home', 'thrClear'],
    name: '写月清',
    icon: 's iconfont iconblue',
    cls: 'monColor',
    path: '/thrclear/writemonth?reportType=3&name=写月清',
    reportType: '3',
    icons: 'iconxyq',
    class: 'iconbg-green',
    btnType: 'warning'
  },
  {
    scen: ['home', 'meeting'],
    name: '开会',
    icon: 'iconfont iconblue',
    cls: 'dateColor',
    path: '/thrclear/holdmeeting',
    icons: 'iconkh',
    class: 'iconbg-yellow',
    btnType: 'primary'
  },
  {
    scen: ['home', 'meeting'],
    name: '会议决议',
    icon: 'iconfont iconorange',
    cls: 'weekColor',
    path: '/thrclear/conferenceres',
    icons: 'iconhyjy',
    class: 'iconbg-blue',
    btnType: 'success'
  }
]
/**
 * 首页-全部应用
 */
export const homeallapp = [
  {
    code: 'target',
    displayName: '工作目标',
    path: '/mytarget/mine',
    icon: 'icongzmb',
    class: 'iconbg-blue',
    msgtype: 1
  },
  {
    code: 'rpmt',
    displayName: '三清两会',
    path: '/thrclear',
    icon: 'iconsqlh',
    class: 'iconbg-green',
    msgtype: 3
  },
  {
    code: 'orgpf',
    displayName: '组织绩效',
    path: '/organizational',
    icon: 'iconzzjx',
    class: 'iconbg-yellow',
    msgtype: 2
  },
  {
    code: 'evaluate',
    displayName: '民主互评',
    path: '/democracy',
    icon: 'iconmzhp',
    class: 'iconbg-blue',
    msgtype: 4
  },
  {
    code: 'innovate',
    displayName: '创新成果',
    path: '/innovations',
    icon: 'iconcxcg',
    class: 'iconbg-green',
    msgtype: 6
  },
  {
    code: 'contribute',
    displayName: '团队贡献',
    path: '/team',
    icon: 'icontdgx',
    class: 'iconbg-yellow',
    msgtype: 7
  },
  {
    code: 'satisfaction',
    displayName: '客户满意',
    path: '/satisfaction?moduleName=received',
    icon: 'iconkhmy',
    class: 'iconbg-red',
    msgtype: 5
  },
  {
    code: 'deduction',
    displayName: '行为规范',
    path: '/behaviour',
    icon: 'iconxwgf',
    class: 'iconbg-blue',
    msgtype: 0
  },
  {
    code: 'customize1',
    displayName: '自定义维度1',
    path: '/customer',
    icon: 'iconzidingyiweidu',
    class: 'iconbg-blue',
    msgtype: 0
  },
  {
    code: 'customize2',
    displayName: '自定义维度2',
    path: '/customer2',
    icon: 'iconzidingyiweidu',
    class: 'iconbg-blue',
    msgtype: 0
  },
  { code: 'other', displayName: '其它', path: '', icon: 'iconqitaxiaoxi', class: 'iconbg-blue', msgtype: 8 }
]
/**
 * 首页-其他
 */
export const homeotherapp = [
  /* {
    name: '持续奋斗奖',
    icon: 'iconcxfdj',
    class: 'iconbg-yellow',
    path: ''
  }, */
  {
    name: '我的组织',
    icon: 'iconwdzz',
    class: 'iconbg-green',
    path: '/personalCenter/organization'
  }
]
/**
 * 勋章
 */
export const medal = [
  { medalName: '蓝星', medalType: 'blueStars', icon: '/images/xzicon01.png', color: '#2187E8' },
  { medalName: '红星', medalType: 'redStars', icon: '/images/xzicon02.png', color: '#EC1515' },
  { medalName: '金星', medalType: 'goldStars', icon: '/images/xzicon03.png', color: '#FDB90D' },
  { medalName: '钻石', medalType: 'diamond', icon: '/images/xzicon04.png', color: '#5A16DA' },
  { medalName: '元勋', medalType: 'fathers', icon: '/images/xzicon05.png', color: '#E77B36' }
]

/**
 * 返回审批状态
 */
export const approveState = [
  { approveName: '待邀请', color: '#FE831A ' },
  { approveName: '审批中', color: '#3D7EFF ' },
  { approveName: '审批通过', color: '#06AE56' },
  { approveName: '已驳回', color: '#FF526A' },
  { approveName: '系统自动驳回', color: '#FF526A' },
  { approveName: '管理员驳回', color: '#FF526A' }
]

export const reportType = [
  {
    name: '日评',
    type: 1
  },
  {
    name: '周评',
    type: 2
  },
  {
    name: '旬评',
    type: 3
  },
  {
    name: '月评',
    type: 4
  },
  {
    name: '组织月评',
    type: 4
  }
]

/**
 * 消息
 */
// 面包屑
export const msgBreadcrum = [
  {
    title: '首页',
    route: '/'
  },
  {
    title: '消息',
    route: 'message'
  }
]
export const messageTabs = [
  {
    name: 'nodone',
    type: 1,
    label: '待办'
  },
  {
    name: 'done',
    type: 2,
    label: '已办'
  },
  {
    name: 'msglist',
    type: 0,
    label: '消息'
  }
]
/**
 * 打分组件-分值描述
 */
export const RATE_DESC = ['非常差', '差', '一般', '好', '非常好']

export const myTarget = {
  // 二级导航
  nav: [
    {
      id: '1',
      label: '我的目标',
      name: 'mytarget-mine',
      path: '/mytarget',
      icon: 'iconhome-sort1'
    },
    {
      id: '2',
      label: '目标考评',
      name: 'mytarget-evaluation',
      path: '/mytarget/evaluation',
      icon: 'iconhome-sort1'
    },
    {
      id: '3',
      label: '年度目标',
      name: 'annual-target',
      path: '/mytarget/annual',
      icon: 'iconhome-sort1'
    }
  ],

  // 面包屑
  breadcrum: [
    {
      title: '首页',
      route: 'Home'
    },
    {
      title: '工作目标',
      route: 'mytarget-mine'
    }
  ]
}

export const thrClear = {
  // 二级导航
  nav: [
    {
      id: '1',
      label: '三清',
      name: 'thrclear-mine',
      path: '/thrclear',
      icon: 'iconminzhuhuping'
    },
    {
      id: '2',
      label: '会议',
      name: 'thrclear-meeting',
      path: '/thrclear/meeting',
      icon: 'iconevaluation'
    }
  ],

  // 面包屑
  breadcrum: [
    {
      title: '首页',
      route: 'Home'
    },
    {
      title: '三清两会',
      route: 'thrclear-mine'
    }
  ]
}
