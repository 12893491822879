export default [
  {
    path: '/organizational',
    name: 'organizational',
    redirect: '/organizational/scoreSum',
    component: () => import(/* webpackChunkName: "index" */ '@/views/organizational/index.vue'),
    meta: {
      title: '组织绩效'
    },
    children: [
      {
        path: 'scoreSum',
        name: 'scoreSum',
        component: () =>
          import(/* webpackChunkName: "scoreSum" */ '@/views/organizational/scoreSum.vue'),
        meta: {
          id: '1',
          title: '组织绩效',
          module: 'organizational',
          pid: '0'
        }
      },
      {
        path: 'appraisal',
        name: 'appraisal',
        component: () =>
          import(/* webpackChunkName: "appraisal" */ '@/views/organizational/appraisal.vue'),
        meta: {
          id: '2',
          title: '组织绩效',
          module: 'organizational',
          pid: '0'
        },
        props: route => ({ moduleName: route.query.moduleName || 'review' })
      },
      {
        path: 'organizeResult',
        name: 'organizeResult',
        component: () =>
          import(
            /* webpackChunkName: "organize-result" */ '@/views/organizational/organizeResult.vue'
          ),
        meta: {
          id: '2_1',
          title: '组织考评结果',
          module: 'organizational',
          pid: '2'
        },
        props: route => ({
          date: route.query.date,
          orgId: route.query.orgId,
          name: route.query.name,
          levelList: route.query.levelList
        })
      },
      {
        path: 'personalResult',
        name: 'personalResult',
        component: () =>
          import(
            /* webpackChunkName: "personal-result" */ '@/views/organizational/personalResult.vue'
          ),
        meta: {
          id: '2_2',
          title: '个人考评结果',
          module: 'organizational',
          pid: '2'
        },
        props: route => ({
          date: route.query.date,
          orgId: route.query.orgId,
          name: route.query.name,
          levelList: route.query.levelList
        })
      },
      {
        path: 'probationDepartureResult',
        name: 'probationDepartureResult',
        component: () =>
          import(
            /* webpackChunkName: "probation-departure-result" */ '@/views/organizational/probationDepartureResult.vue'
          ),
        meta: {
          id: '2_5',
          title: '单独考评',
          module: 'organizational',
          pid: '2'
        },
        props: route => ({
          date: route.query.date,
          orgId: route.query.orgId,
          id: route.query.id,
          name: route.query.name,
          count: route.query.count,
          type: route.query.type
        })
      },
      {
        path: 'personalEvaluation',
        name: 'personalEvaluation',
        component: () =>
          import(
            /* webpackChunkName: "personalEvaluation" */ '@/views/organizational/personalEvaluation.vue'
          ),
        meta: {
          id: '2_3',
          title: '个人绩效考评',
          module: 'organizational',
          pid: '2'
        },
        props: route => ({
          date: route.query.date,
          orgId: route.query.orgId,
          name: route.query.name,
          count: route.query.count,
          cfgId: route.query.cfgId,
          id: route.query.id
        })
      },
      {
        path: 'organizeEvaluation',
        name: 'organizeEvaluation',
        component: () =>
          import(
            /* webpackChunkName: "organizeEvaluation" */ '@/views/organizational/organizeEvaluation.vue'
          ),
        meta: {
          id: '2_4',
          title: '组织绩效考评',
          module: 'organizational',
          pid: '2'
        },
        props: route => ({
          date: route.query.date,
          orgId: route.query.orgId,
          name: route.query.name,
          count: route.query.count,
          cfgId: route.query.cfgId,
          id: route.query.id
        })
      }
    ]
  }
]
