/* 存放公共API */

import * as http from './http'

//登录
export const login = params => http.post('/public/auth/login', params)
export const secret = params => http.get('/public/auth/secret', params)
export const getCode = params => http.post('/public/auth/verify-code', params)
//获取图形验证码
export const getImgCode = params => http.post('/public/auth/img-code', params)
//获取协议内容
export const getAgreement = params => http.post('/public/agreement/getDetail', params)
//登出
export const logout = params => http.post('/logout', params)
// 根据员工名称搜索员工
export const reqGetEmpListByName = params => http.post('/user/search-by-name', params, { loading: false })
// 批量查询员工
export const reqGetEmpListByIds = params => http.post('/user/batch-query-user', params, { loading: false })

// 上传文件、图片接口[多文件]
export const upload = params => http.post('/my/file/upload', params)

//首页
export const homePc = params => http.post('/home/pc', params)
//首页消息列表
export const msgList = params => http.post('/message/list', params)
//首页消息数量
export const msgCount = params => http.post('/message/unread-count', params)
//消息已读
export const msgRead = params => http.post('/message/read', params)
//全部已读
export const msgAllRead = params => http.post('/message/read-all', params)

//组织架构
export const orgList = params => http.post('/org/query-org-tree', params)
//子组织和员工列表查询
export const orgUserList = params => http.post('/org/child/all', params)
export const searchByName = params => http.post('/user/search-by-user-name', params)
//获取用户信息
export const userInfo = params => http.post('/user/data', params, { loading: false })
//修改密码
export const editPassword = params => http.post('/auth/passwd', params)
//选择企业
export const changeCompany = params => http.post('/auth/to-tenant', params)

// 删除消息
export const delMessage = params => http.post('/message/del-all', params)

// 是否天九
export const isTojoy = params => http.post('/enterprise/config/isTojoy', params)
