export default [
  {
    path: '/democracy',
    name: 'democracy',
    redirect: '/democracy/mine',
    component: () => import(/* webpackChunkName: "democracy" */ '@/views/democracy/index.vue'),
    children: [
      {
        path: '',
        redirect: 'mine'
      },
      {
        path: 'mine',
        name: 'democracy-mine',
        component: () => import(/* webpackChunkName: "democracy-mine" */ '@/views/democracy/mine.vue'),
        meta: {
          id: '1',
          title: '民主互评',
          module: 'democracy',
          pid: '0'
        },
        /**
         * 收到的评价：received
         * 待我评价：comment
         * 我已评的：complete
         */
        props: route => ({ moduleName: route.query.moduleName || 'received' })
      },
      {
        path: 'underlings',
        name: 'democracy-underlings',
        component: () => import(/* webpackChunkName: "democracy-underlings" */ '@/views/democracy/underlings.vue'),
        meta: {
          id: '2',
          title: '民主互评',
          module: 'democracy',
          pid: '0'
        },
        /**
         * 我的团队 myTeam
         * 待我审批 approve
         * 我已审批 alreadyApprove
         */
        props: route => ({ moduleName: route.query.moduleName || 'myTeam' })
      },
      {
        path: 'inviter',
        name: 'democracy-inviter',
        component: () => import(/* webpackChunkName: "democracy-inviter" */ '@/views/democracy/inviter.vue'),
        meta: {
          id: '1_1',
          title: '我的评价人',
          module: 'democracy',
          pid: '1'
        }
      }
    ]
  }
]
