import * as types from './mutation-types'
import _ from 'lodash'
import { reqGetEmpListByName, userInfo, msgCount } from '../api/api_public'

/**
 * 获取用户信息
 * @param commit
 */
export const queryPersonList = ({ commit }, query) => {
  reqGetEmpListByName({ name: query || '', isAccess: 0 }).then(res => {
    const personList = res.data.userList
    commit(types.SET_PERSON_LIST, personList)
  })
}

export const getUserInfo = ({ commit }, params) => {
  userInfo(params).then(res => {
    if (res && res.code === '000000') {
      commit(types.SET_USER_INFO, _.cloneDeep(res.data))
    }
  })
}
/**
 * 获取消息数量
 * @param {*} param0 
 * @param {*} params 
 */
export const getMsgCount = ({ commit }) => {
  msgCount().then(res => {
    if (res && res.code === '000000') {
      commit('SET_MSG_NUMBER', res.data)
    }
  })
}