
export default [
  {
    path: '/mytarget',
    name: 'mytarget',
    redirect: '/mytarget/mine',
    component: () => import(/* webpackChunkName: "democracy" */ '@/views/mytarget/index.vue'),
    meta: {
      title: '工作目标'
    },
    children: [
      {
        path: '',
        redirect: 'mine'
      },
      {
        path: 'mine',
        name: 'mytarget-mine',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/mytarget/mine.vue'),
        meta: {
          id: '1_1',
          title: '工作目标',
          module: 'mytarget',
          pid: '0'
        }
      },
      {
        path: 'evaluation',
        name: 'mytarget-evaluation',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/mytarget/evaluation-done.vue'),
        meta: {
          id: '1_2',
          title: '目标考评',
          module: 'mytarget',
          pid: '0',
        }

      },
      {
        path: 'deatils',
        name: 'mytarget-my-details',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/mytarget/my-details.vue'),
        meta: {
          id: '2_1',
          title: '目标详情',
          module: 'mytarget',
          pid: '1_1'
        }
      },
      {
        path: 'ldeatils',
        name: 'mytarget-my-lead-details',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/mytarget/my-lead-details.vue'),
        meta: {
          id: '2_2',
          title: '目标详情',
          module: 'mytarget',
          pid: '1_2'
        }
      },
      {
        path: 'annual',
        name: 'annual-target',
        component: () =>
          import(/* webpackChunkName: "annual-target" */ '@/views/mytarget/annual/annualSummary.vue'),
        meta: {
          id: '1_3',
          title: '年度目标',
          module: 'mytarget',
          pid: '0'
        }
      },
      {
        path: 'annualdetail',
        name: 'annual-detail',
        component: () =>
          import(/* webpackChunkName: "annual-target" */ '@/views/mytarget/annual/annualDetail.vue'),
        meta: {
          id: '1_4',
          title: '目标详情',
          module: 'mytarget',
          pid: '1_3',
          routerRefresh:true
        }
      }
    ]
  }
]
