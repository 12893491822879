import * as http from './http'

// 我的满意度-我收到的
export const mineReceive = (params, options) =>
  http.post('/satisfaction/evaluate/receive', params, options)

// 我的满意度-我收到的-申诉
export const mineAppeal = params => http.post('/satisfaction/evaluate/appeal', params)

// 我的满意度-待我评价-查询列表
export const mineEvaluateQuery = params => http.post('/satisfaction/evaluate/query', params)

// 我的满意度-待我评价-批量提交
export const mineEvaluateSave = params => http.post('/satisfaction/evaluate/save', params)

// 我的满意度-我已评价-查询列表
export const mineEvaluateQueryDetail = (params, options) =>
  http.post('/satisfaction/evaluate/query/detail', params, options)

// 我的满意度-邀请人评价人-查询
export const mineInviteQuery = params => http.post('/satisfaction/invite/query', params, {loading: false})

// 我的满意度-邀请人评价人-申请
export const mineInviteApprove = params => http.post('/satisfaction/invite/save', params)

// 下属满意度-待我审核列表
export const underlingsApproveList = (params, options) =>
  http.post('/satisfaction/invite/query/audit', params, options)

// 下属满意度-待我审核-删除
export const underlingsApproveDel = params => http.post('/satisfaction/invite/audit/del', params)

// 下属满意度-待我审核-提交审核表单
export const underlingsApproveAudit = params => http.post('/satisfaction/invite/audit', params)

// 下属满意度-待我审核-添加人员
export const unserlingsApproveAdd = params => http.post('/satisfaction/invite/audit/add', params)

// 下属满意度-团队满意度
export const underlingsSatisfaction = (params, options) =>
  http.post('/satisfaction/evaluate/team/satisfaction', params, options)

// 下属满意度-团队满意度-投诉处理列表
export const underlingsComplaintList = (params, options) =>
  http.post('/satisfaction/evaluate/complaint/list', params, options)

// 下属满意度-团队满意度-投诉审核
export const underlingsComplaintAudit = params =>
  http.post('/satisfaction/evaluate/complaint/audit', params)

// 下属满意度-团队满意度-感动处理列表
export const underlingsTouchList = (params, options) =>
  http.post('/satisfaction/evaluate/touch/list', params, options)

// 下属满意度-团队满意度-感动审核
export const underlingsTouchAudit = params =>
  http.post('/satisfaction/evaluate/touch/audit', params)

// 我的满意度-红点
export const mineRed = params => http.post('/satisfaction/evaluate/customer/satisfaction', params, {loading: false})

// 团队满意度-红点
export const teamRed = params => http.post('/satisfaction/evaluate/team/statistics', params, {loading: false})
