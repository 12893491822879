var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"explanation"},[(_vm.showTip)?_c('el-dialog',{attrs:{"append-to-body":"","visible":_vm.showTip,"title":"特别贡献加减分","width":"560px","center":"","before-close":_vm.handleClose,"custom-class":"appeal-dialog"},on:{"update:visible":function($event){_vm.showTip=$event}}},[_c('div',{staticClass:"dialog-main"},[_c('el-form',{ref:"approveForm",attrs:{"label-position":"top","model":_vm.approveForm,"show-message":true,"inline-message":""}},[_c('el-form-item',{attrs:{"prop":"add","label":"加减分","rules":{
            required: true,
            message: '加减分不能为空',
            trigger: 'change'
          }}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.approveForm.add),callback:function ($$v) {_vm.$set(_vm.approveForm, "add", $$v)},expression:"approveForm.add"}},_vm._l((_vm.addList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.value,"value":item.code}})}),1)],1),_c('el-form-item',{attrs:{"prop":"remarks","label":"加减分说明","rules":{
            required: true,
            message: '加减分说明不能为空',
            trigger: 'blur'
          }}},[_c('el-input',{ref:"remarks",attrs:{"type":"textarea","rows":"1","placeholder":"加减分说明（必填，限20字）","maxlength":"20","show-word-limit":"","resize":"none"},model:{value:(_vm.approveForm.remarks),callback:function ($$v) {_vm.$set(_vm.approveForm, "remarks", $$v)},expression:"approveForm.remarks"}})],1)],1)],1),_c('template',{slot:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleApproveSubmit}},[_vm._v("确定")])],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }