<template>
  <div class="explanation">
    <el-dialog append-to-body :visible.sync="showTip" v-if="showTip" title="特别贡献加减分" width="560px" center :before-close="handleClose" custom-class="appeal-dialog">
      <div class="dialog-main">
        <el-form label-position="top" :model="approveForm" :show-message="true" inline-message ref="approveForm">
          <el-form-item
            prop="add"
            label="加减分"
            :rules="{
              required: true,
              message: '加减分不能为空',
              trigger: 'change'
            }"
          >
            <el-select v-model="approveForm.add" placeholder="请选择">
              <el-option :label="item.value" :value="item.code" v-for="(item, index) in addList" :key="index" />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="remarks"
            label="加减分说明"
            :rules="{
              required: true,
              message: '加减分说明不能为空',
              trigger: 'blur'
            }"
          >
            <el-input ref="remarks" type="textarea" rows="1" placeholder="加减分说明（必填，限20字）" maxlength="20" show-word-limit resize="none" v-model="approveForm.remarks" />
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleApproveSubmit">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import '@/components/democracy/edit-dialog.scss'
export default {
  name: 'pass',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    cIndex: {
      type: Number,
      default: 0
    },
    val: {
      type: Number,
      default: 0
    },
    remark: {
      type: String,
      default: ''
    }
  },
  data() {
    let handleSum = () => {
      let max = 3.1
      let arr = Array.from(
        { length: 61 },
        () => {
          max = parseFloat((max - 0.1).toFixed(1))
          let obj = {}
          let tip = max > 0 ? '+' : ''
          this.$set(obj, 'code', max)
          this.$set(obj, 'value', `${tip}${max}分`)
          return obj
        },
        1
      )
      return arr
    }
    return {
      addList: handleSum(),
      approveForm: {
        add: '',
        remarks: '' //备注
      },
      showTip: this.showDialog
    }
  },
  methods: {
    handleApproveSubmit() {
      this.$refs['approveForm'].validate(valid => {
        if (valid) {
          let { add, remarks } = this.approveForm
          this.$emit('valChange', this.cIndex, add, remarks)
          this.showTip = false
          this.$parent.showDialog = false
          this.approveForm = {}
        } else {
          return false
        }
      })
    },
    handleClose() {
      this.showTip = false

      this.$parent.showDialog = false
      this.approveForm = {}
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
      this.$set(this.approveForm, 'add', this.val)
      this.$set(this.approveForm, 'remarks', this.remark)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input__inner {
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0 10px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: $white;
}
/deep/.el-form-item__content {
  line-height: 1;
}
/deep/.appeal-dialog .el-dialog__body .el-textarea__inner {
  padding: 5px 0;
}
/deep/.el-textarea .el-input__count {
  bottom: 10px;
}
</style>
