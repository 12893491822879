export default [
  {
    path: '/homepage',
    name: 'homepage',
    redirect: '/homepage/navigation',
    component: () => import(/* webpackChunkName: "homepage" */ '@/views/homepage/index.vue'),
    meta: {
      title: '个人中心'
    },
    children: [
      {
        path: '',
        redirect: 'navigation'
      },
      {
        path: 'navigation',
        name: 'homepage-navigation',
        component: () =>
          import(/* webpackChunkName: "homepage-navigation" */ '@/views/homepage/navigation.vue'),
        meta: {
          id: '1',
          title: '个人中心',
          module: 'homepage',
          pid: '0'
        }
      }
    ]
  }
]
