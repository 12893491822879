<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      :visible.sync="showTip"
      v-if="showTip"
      :title="`${userObj.evalName}绩效总分详情`"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <div class="box" v-if="pageType === 'organization'">
          <div class="title">
            组织绩效分<span class="tip-color">{{ tipVal }}</span>
          </div>
          <div class="value" :class="userObj.orgTotalScore < 0 ? 'red' : ''">
            {{ userObj.orgTotalScore || 0 }}分
          </div>
        </div>
        <div
          class="box"
          v-if="
            (pageType === 'personal' || pageType === 'team') && typeof userObj.target === 'number'
          "
        >
          <div class="title">
            工作目标<span class="tip-color">{{ tipVal }}</span>
          </div>
          <div class="value" :class="userObj.target < 0 ? 'red' : ''">
            {{ userObj.target || 0 }}分
          </div>
        </div>
        <div class="box" v-if="pageType === 'team' && typeof userObj.evaluate === 'number'">
          <div class="title">组织互评</div>
          <div class="value" :class="userObj.evaluate < 0 ? 'red' : ''">
            {{ userObj.evaluate || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.rpmt === 'number'">
          <div class="title">三清两会</div>
          <div class="value" :class="userObj.rpmt < 0 ? 'red' : ''">{{ userObj.rpmt || 0 }}分</div>
        </div>
        <div class="box" v-if="pageType === 'personal' && typeof userObj.evaluate === 'number'">
          <div class="title">民主互评</div>
          <div class="value" :class="userObj.evaluate < 0 ? 'red' : ''">
            {{ userObj.evaluate || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.satisfaction === 'number'">
          <div class="title">客户满意</div>
          <div class="value" :class="userObj.satisfaction < 0 ? 'red' : ''">
            {{ userObj.satisfaction || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.deduction === 'number'">
          <div class="title">行为规范</div>
          <div class="value" :class="userObj.deduction < 0 ? 'red' : ''">
            {{ userObj.deduction || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.innovate === 'number'">
          <div class="title">
            创新成果<span class="tip-color">{{ itemVal }}</span>
          </div>
          <div class="value" :class="userObj.innovate < 0 ? 'red' : ''">
            {{ userObj.innovate || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.contribute === 'number'">
          <div class="title">
            团队贡献<span class="tip-color">{{ itemVal }}</span>
          </div>
          <div class="value" :class="userObj.contribute < 0 ? 'red' : ''">
            {{ userObj.contribute || 0 }}分
          </div>
        </div>
        <div class="box" v-if="pageType === 'personal'">
          <div class="title">组织绩效</div>
          <div class="value" :class="userObj.orgTotalScore < 0 ? 'red' : ''">
            {{ userObj.orgTotalScore || 0 }}分
          </div>
        </div>
        <div class="box">
          <div class="title">特别扣分</div>
          <div class="value" :class="userObj.partakeExtraScore < 0 ? 'red' : ''">
            {{ userObj.partakeExtraScore || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.customize1 === 'number' && userObj.customize1Name">
          <div class="title">{{ userObj.customize1Name }}</div>
          <div class="value" :class="userObj.customize1 < 0 ? 'red' : ''">
            {{ userObj.customize1 || 0 }}分
          </div>
        </div>
        <div class="box" v-if="typeof userObj.customize2 === 'number' && userObj.customize2Name">
          <div class="title">{{ userObj.customize2Name }}</div>
          <div class="value" :class="userObj.customize2 < 0 ? 'red' : ''">
            {{ userObj.customize2 || 0 }}分
          </div>
        </div>
        <div class="box-sum">
          <span class="title">合计:</span>
          <span class="value">{{ sumVal }}分</span>
        </div>
        <p class="box-tip" v-if="pageType === 'team'">
          <em class="name">{{ userObj.evalName }}</em
          >{{ textval }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import '@/components/democracy/edit-dialog.scss'
import { formateNumber } from '@/utils/index.js'
export default {
  name: 'user-detail',
  props: {
    pageType: {
      type: String,
      default: 'personal' //personal 个人 organization 组织 //team不占比
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    userObj: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: [Number, String],
      default: 2
    },
    benchmark: {
      type: Number,
      default: 0
    },
    averageScore: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showTip: this.showDialog,
      tip: ''
    }
  },
  computed: {
    itemVal() {
      let str = ''
      if (this.pageType !== 'team') {
        str = this.benchmark && this.userObj.target < this.averageScore ? '(不计入总分)' : ''
      }
      return str
    },
    tipVal() {
      let str = ''
      if (this.benchmark) {
        if ([3, 4].includes(this.benchmark)) {
          //基准分
          str = this.userObj.target < this.averageScore ? `(低于基准分${this.averageScore}分)` : ''
        } else {
          //平均分
          str = this.userObj.target < this.averageScore ? `(低于平均分${this.averageScore}分)` : ''
        }
      }
      return str
    },
    textval() {
      let str =
        this.flag === 1
          ? '不占强制分布比例名额，需要直接给出个人绩效结果'
          : '不占强制分布比例名额，组织绩效考评完成后，从“待我考评”列表单独给出个人绩效结果'
      return str
    },

    sumVal() {
      if (this.pageType === 'organization') {
        let {
          orgTotalScore = 0,
          rpmt = 0,
          satisfaction = 0,
          deduction = 0,
          innovate = 0,
          contribute = 0,
          partakeExtraScore = 0,
          customize1 = 0,
          customize2 = 0,
          target = 0
        } = this.userObj
        if (!this.isCompute(target, this.averageScore)) {
          innovate = 0
          contribute = 0
        }
        let sum =
          orgTotalScore +
          rpmt +
          satisfaction +
          deduction +
          innovate +
          contribute +
          partakeExtraScore +
          customize1 +
          customize2
        return formateNumber(sum)
      } else if (this.pageType === 'team') {
        let {
          target = 0,
          evaluate = 0,
          rpmt = 0,
          satisfaction = 0,
          deduction = 0,
          innovate = 0,
          contribute = 0,
          partakeExtraScore = 0,
          customize1 = 0,
          customize2 = 0
        } = this.userObj
        let sum =
          target +
          evaluate +
          rpmt +
          satisfaction +
          deduction +
          innovate +
          contribute +
          partakeExtraScore +
          customize1 +
          customize2
        return formateNumber(sum)
      } else {
        let {
          target = 0,
          evaluate = 0,
          orgTotalScore = 0,
          rpmt = 0,
          satisfaction = 0,
          deduction = 0,
          innovate = 0,
          contribute = 0,
          partakeExtraScore = 0,
          customize1 = 0,
          customize2 = 0
        } = this.userObj
        if (!this.isCompute(target, this.averageScore)) {
          innovate = 0
          contribute = 0
        }
        let sum =
          target +
          evaluate +
          orgTotalScore +
          rpmt +
          satisfaction +
          deduction +
          innovate +
          contribute +
          partakeExtraScore +
          customize1 +
          customize2
        return formateNumber(sum)
      }
    }
  },
  methods: {
    isCompute(target, averageScore) {
      if (this.benchmark) {
        return target < averageScore ? false : true
      } else {
        return true
      }
    },
    handleClose() {
      this.showTip = false
      this.$parent.showDetailDialog = false
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-bottom: 20px;
}
.box {
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 0px 0px $linecolor;
  padding: 18px 0px;
  font-size: 14px;
  font-weight: 400;
  color: $darkgrey;
  line-height: 14px;
  .title {
    flex: 1;
  }
  .value {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: $blue;
  }
  .red {
    color: $red;
  }
}
.box-sum {
  text-align: center;
  padding-top: 20px;
  //padding-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: $darkgrey;
  line-height: 16px;
  .value {
    color: $blue;
    margin-left: 10px;
  }
}
.box-tip {
  margin-top: 20px;
  padding: 15px;
  font-size: 14px;
  color: #3d7eff;
  line-height: 20px;
  background: rgba(61, 126, 255, 0.05);
  border-radius: 3px;
  .name {
    font-weight: 600;
  }
}
.tip-color {
  color: $FF526A;
  margin-left: 10px;
}
</style>
