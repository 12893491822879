<template>
  <div class="result-footer">
    <div class="compony">
      <div class="name">{{ obj.name }}</div>
      <div class="date">
        <slot name="title">
          <span v-if="model === 'B'"
            >本月参评{{ pageType === 'organization' ? '组织' : '人数' }}{{ obj.count
            }}{{ pageType === 'organization' ? '个' : '人'
            }}<span v-if="levelData.S > 0 || levelData.A > 0 || levelData.C > 0"
              >，上月{{ levelData.S ? `S节余${levelData.S}` : ''
              }}{{ levelData.S && (levelData.A || levelData.C) ? '，' : ''
              }}{{ levelData.A ? `A节余${levelData.A}` : ''
              }}{{ levelData.A && levelData.C ? '，' : ''
              }}{{ levelData.C ? `C节余${levelData.C}` : '' }}</span
            ></span
          >
          <div v-else>
            <span>考评月份:</span><span>{{ obj.date | formateDate }}</span>
          </div>
        </slot>
      </div>
    </div>
    <div class="level-list">
      <div class="level-box" v-for="(item, index) in list" :key="index">
        <level-model :item="item" :index="index">
          <template #grade v-if="model === 'B'">
            <span class="big">{{ item.big || 0 }}</span>
            <span class="small" v-if="item.level !== 'B'">余{{ parseFloat(item.small) || 0 }}</span>
          </template>
          <template #rank v-if="model === 'B'">
            <span class="percent">占{{ item.ratio | formateNumber }}</span>
          </template>
        </level-model>
      </div>
    </div>
  </div>
</template>

<script>
import levelModel from '@/components/organizational/level-model'
export default {
  name: 'result-footer',
  components: { levelModel },
  props: {
    pageType: {
      type: String,
      default: 'personal' //personal 个人 organization 组织
    },
    levelData: {
      type: Object,
      default: () => {}
    },
    obj: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    },
    model: {
      type: String,
      default: 'A'
    }
  },
  filters: {
    formateNumber(val = 0) {
      return `${parseInt(val * 100)}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.result-footer {
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  padding: 20px 0px;
  margin-left: 20px;
  flex: 4;
  .compony {
    flex: 1;
    .name {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $lightblack;
      line-height: 20px;
      margin-bottom: 15px;
    }
    .date {
      font-size: 14px;

      font-weight: 400;
      color: $darkgrey;
      line-height: 14px;
    }
  }
  .level-list {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .level-box {
      padding-left: 30px;
      padding-right: 30px;
      border-right: 1px solid #eeeeee;
      text-align: center;
    }
    .big {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: $blue;
      line-height: 21px;
    }
    .small {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: $grey;
      line-height: 12px;
    }
    .percent {
      color: $darkgrey;
    }
    .level-box:last-child {
      border: none;
    }
  }
}
</style>
