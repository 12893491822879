const moduleBreadcrumb = {
  state: () => ({
    breadcrumbs: []
  }),

  mutations: {
    setBreadcrumbs(state, data) {
      state.breadcrumbs = data
    }
  },

  actions: {},

  getters: {}
}

export default moduleBreadcrumb
