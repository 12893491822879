import * as http from './http'
//  我的得分列表
export const mySum = params => http.post('/evalStaff/list/group/ym', params)
// 组织评比列表
export const evalOrgList = params => http.post('/evalOrg/list/group/ym', params)
//  考评(参评)组织列表
export const organizationList = params => http.post('/evalOrgCfg/listOrg', params)

//绩效考评 -待我考评  /eval/not
export const waitMeAppraisal = params => http.post('/eval/not', params)
//绩效考评 -过往考评 /eval/history
export const pastEvaluation = params => http.post('/eval/history', params)

//过往考评 - 组织绩效详情
export const getEvalResult = params => http.post('/evalOrg/evalResult', params)
//过往考评 - 组织绩效详情--头部考评统计（废弃）
export const getEvalResultCount = params => http.post('/evalOrg/eval/count', params)
//过往考评- - 个人考评结果详情
export const getStaffEvalResult = params => http.post('/evalStaff/evalResult', params)
//过往考评 - 个人考评结果详情 - 统计-头部
export const getStaffEvalResultCount = params => http.post('/evalStaff/eval/count', params)

//组织绩效考评-提交考评页--列表
export const getEvalOrgTo = params => http.post('/evalOrg/to', params, { loading: false })
//组织绩效--提交考评
export const postEvalOrgSave = params => http.post('/evalOrg/save', params, { interceptRes: false })
//个人绩效考评-提交考评页--列表
export const getEvalStaffTo = params => http.post('/evalStaff/to', params, { loading: false })
//个人--提交考评
export const postEvalStaffSave = params => http.post('/evalStaff/save', params, { interceptRes: false })
//试用期及离职员工--提交考评
export const postEvalStaffSaveSpecial = params => http.post('/evalStaff/save/special', params)
//试用期及离职员工考评
export const getEvalStaffSpecial = params => http.post('/evalStaff/to/special', params, { loading: false })
//试用期及离职参评人--提交考评
export const postEvalStaffSavPartakSpecial = params => http.post('/evalStaff/save/partake/special', params)
//试用期及离职参评人
export const getEvalStafPartakeSpecial = params => http.post('/evalStaff/to/partake/special', params, { loading: false })
//等级字典  /pfCfg/list
export const getLevelList = params => http.post('/pfCfg/list', params)

//组织绩效考评-头部组织信息
export const evalOrg = params => http.post('/eval/org', params, { loading: false })
//个人绩效考评-头部组织信息
export const evalStaff = params => http.post('/eval/staff', params, { loading: false })

// 查询当前用户所在组织
export const queryId = params => http.post('/org/query-parent-ids', params)

// 1.4.0需求 提醒配置达成率
export const remind = params => http.post('/rpar/remind', params)
