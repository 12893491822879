import Vue from 'vue'
Vue.directive('filteremoji', {
  bind: function(el) {
    const elInput = el.getElementsByTagName('input')[0] || el.getElementsByTagName('textarea')[0]
    const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
    const eventArr = ['input', 'blur']
    eventArr.forEach(name => {
      elInput.addEventListener(name, () => {
        if (elInput.value.trim().length > 0) {
          if (reg.test(elInput.value)) {
            elInput.value = elInput.value.replace(reg, '')
            elInput.dispatchEvent(new Event('input'))
          }
        }
      })
    })
  }
})
/**
 * 登录input动画
 */
Vue.directive('inputblur', {
  inserted: function(el, { value }) {
    let label = el.previousSibling
    let input = el.getElementsByTagName('input')[0]
    if (value) {
      label.style.top = '7px'
      label.style.fontSize = '12px'
    } else {
      label.style.top = '27px'
      label.style.fontSize = '14px'
    }
    input.addEventListener('focus', () => {
      label.style.top = '7px'
      label.style.fontSize = '12px'
    })
    input.addEventListener('blur', () => {
      if (value) {
        label.style.top = '7px'
        label.style.fontSize = '12px'
      } else {
        label.style.top = '27px'
        label.style.fontSize = '14px'
      }
    })
  },
  update: function(el, { value }) {
    let label = el.previousSibling
    let input = el.getElementsByTagName('input')[0]
    if (value) {
      label.style.top = '7px'
      label.style.fontSize = '12px'
    } else {
      label.style.top = '27px'
      label.style.fontSize = '14px'
    }
    input.addEventListener('focus', () => {
      label.style.top = '7px'
      label.style.fontSize = '12px'
    })
    input.addEventListener('blur', () => {
      if (value) {
        label.style.top = '7px'
        label.style.fontSize = '12px'
      } else {
        label.style.top = '27px'
        label.style.fontSize = '14px'
      }
    })
  }
})

// 防止重复点击
Vue.directive('preventClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, 2000)
      }
    })
  }
})

// input框默认聚焦
Vue.directive('loadFocus', {
  inserted(el, binding) {
    console.log('focus', el)
    el.children[0].focus()
  }
})

// onlyNumber
Vue.directive('onlyNumber', {
  inserted: function(el, binding, vNode) {
    el = el.getElementsByTagName('input')[0]
    let content
    // 设置输入框的值,触发input事件,改变v-model绑定的值
    const setVal = val => {
      if (vNode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vNode.componentInstance.$emit('input', val)
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.value = val
        el.dispatchEvent(new Event('input'))
      }
    }
    // 按键按下=>只允许输入 数字/小数点
    el.addEventListener('keypress', event => {
      const e = event || window.event
      const inputKey = String.fromCharCode(typeof e.charCode === 'number' ? e.charCode : e.keyCode)
      const re = /\d|\./
      content = e.target.value
      // 定义方法,阻止输入
      function preventInput() {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
      // 表情
      if (!re.test(inputKey) && !e.ctrlKey) {
        preventInput()
      } else if (content.indexOf('.') > 0 && inputKey === '.') {
        // 已有小数点,再次输入小数点
        preventInput()
      }
    })

    el.addEventListener('input', event => {
      // 排除表情
      if (el.value) {
        if (Number.isNaN(Number(el.value))) {
          console.log('是', el.value)
          el.value = ''
          setVal(el.value)
        } else {
          const reg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
          if (reg.test(el.value)) {
            el.value = el.value.replace(reg, '')
          }
          el.value = el.value.replace('。', '.')
          let len = binding.value?.length || 2
          let reg1 = new RegExp('^(\\d+)\\.(\\d{' + len + '}).*$')
          el.value = el.value.replace(reg1, '$1.$2')
          if (el.value.indexOf('.') < 0 && el.value != '') {
            //首位是0的话去掉
            el.value = parseFloat(el.value)
          }
          setVal(el.value)
        }
      }
    })
  },
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('input', () => {})
  }
})
/**
 *去除输入框多余0,例如1.10
 */
Vue.directive('filterZero', {
  bind: function(el, binding, vnode) {
    let elInput = el.children[2].children[0]
    elInput.value = vnode.data.model.value ? parseFloat(vnode.data.model.value) : 0

    elInput.addEventListener('blur', () => {
      elInput.value = elInput.value ? parseFloat(elInput.value) : 0
      if (vnode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vnode.componentInstance.$emit('input', elInput.value)
      } else {
        // 如果是原生组件就触发原生组件的input事件
        elInput.dispatchEvent(new Event('input'))
      }
    })
    elInput.addEventListener('input', () => {
      let reg1 = new RegExp('^(\\d+)\\.(\\d{' + 2 + '}).*$')
      elInput.value = elInput.value.replace(/[^\d.]/g, '')
      elInput.value = elInput.value.replace(reg1, '$1.$2') // 限制只能输入两个小数
    })

    elInput.dispatchEvent(new Event('input'))
  }
})
Vue.directive('filterTrim', {
  inserted: function(el, binding, vnode) {
    let elInput = el.children[0]
    elInput.addEventListener('blur', () => {
      if (!elInput.value.trim()) {
        elInput.value = ''
        elInput.dispatchEvent(new Event('input'))
        //校验字段如果是动态表单，prop暂时无法动态获取到，需要通过name传进来
        vnode.context.$refs.ruleForm.validateField(binding.name)
      }
    })
  }
})
