export default [
  {
    path: '/personalCenter',
    name: 'personalCenter',
    redirect: '/personalCenter/material',
    component: () => import(/* webpackChunkName: "personalCenter" */ '@/views/personalCenter/index.vue'),
    meta: {
      title: '个人中心'
    },
    children: [
      {
        path: '',
        redirect: 'material',
      },
      // 我的资料
      {
        path: 'material',
        name: 'personalCenter-material',
        component: () => import(/* webpackChunkName: "personalCenter-material" */ '@/views/personalCenter/material.vue'),
        meta: {
          id: '1_1',
          title: '我的资料',
          module: 'personalCenter',
          pid: '0'
        }
      },
      // 意见反馈
      {
        path: 'opinion',
        name: 'personalCenter-opinion',
        component: () =>
          import(/* webpackChunkName: "personalCenter-opinion" */ '@/views/personalCenter/opinion.vue'),
          meta: {
            id: '1_2',
            title: '意见反馈',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 我关注的
      {
        path: 'myattention',
        name: 'personalCenter-myattention',
        component: () =>
          import(/* webpackChunkName: "personalCenter-myattention" */ '@/views/personalCenter/attentionme.vue'),
          meta: {
            id: '1_3',
            title: '我关注的',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 关注我的
      {
        path: 'attentionme',
        name: 'personalCenter-attentionme',
        component: () =>
          import(/* webpackChunkName: "personalCenter-attentionme" */ '@/views/personalCenter/attentionme.vue'),
          meta: {
            id: '1_4',
            title: '关注我的',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 隐私协议
      {
        path: 'agreement',
        name: 'personalCenter-agreement',
        component: () =>
          import(/* webpackChunkName: "personalCenter-agreement" */ '@/views/personalCenter/agreement.vue'),
          meta: {
            id: '1_5',
            title: '隐私协议',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 关于我们
      {
        path: 'aboutus',
        name: 'personalCenter-aboutus',
        component: () =>
          import(/* webpackChunkName: "personalCenter-aboutus" */ '@/views/personalCenter/aboutus.vue'),
          meta: {
            id: '1_6',
            title: '关于我们',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 提醒设置
      {
        path: 'reminder',
        name: 'personalCenter-reminder',
        component: () =>
          import(/* webpackChunkName: "personalCenter-reminder" */ '@/views/personalCenter/reminder.vue'),
          meta: {
            id: '1_7',
            title: '提醒设置',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 设置
      {
        path: 'settings',
        name: 'personalCenter-settings',
        component: () =>
          import(/* webpackChunkName: "personalCenter-settings" */ '@/views/personalCenter/settings.vue'),
          meta: {
            id: '1_8',
            title: '设置',
            module: 'personalCenter',
            pid: '0'
          }
      },
      // 我的组织
      {
        path: 'organization',
        name: 'personalCenter-organization',
        component: () =>
          import(/* webpackChunkName: "personalCenter-organization" */ '@/views/personalCenter/organization.vue'),
          meta: {
            id: '1_9',
            title: '我的组织',
            module: 'personalCenter',
            pid: '0'
          }
      },
    ]
  }
]
