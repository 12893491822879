<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      :visible.sync="showTip"
      v-if="showTip"
      title="调整本月S/A数量"
      width="560px"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <el-form
          label-position="top"
          :model="approveForm"
          :show-message="true"
          inline-message
          ref="approveForm"
        >
          <el-form-item prop="S" label="S数量">
            <el-select v-model="S" placeholder="请选择">
              <el-option
                :label="item.value"
                :value="item.code"
                v-for="(item, index) in SList"
                :key="index"
              />
            </el-select>
          </el-form-item>
          <el-form-item prop="A" label="A数量">
            <el-select v-model="A" placeholder="请选择">
              <el-option
                :label="item.value"
                :value="item.code"
                v-for="(item, index) in AList"
                :key="index"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button type="primary" size="small" @click="handleApproveSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import '@/components/democracy/edit-dialog.scss'
export default {
  name: 'pass',
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      S: 0,
      A: 0,
      SList: [],
      AList: [],
      approveForm: {},
      showTip: this.showDialog
    }
  },
  methods: {
    handleApproveSubmit() {
      let { count, C } = this.obj
      let B = count - this.S - this.A - C
      if (B < 0) {
        this.$msgError('调整后B数量<0,请重新调整!')
        return false
      }
      this.$emit('resetTopdata', this.S, this.A)
      this.showTip = false
      this.$parent.showPassDialog = false
    },
    handleClose() {
      this.showTip = false
      this.$parent.showPassDialog = false
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
      if (this.showTip) {
        this.S = Number(this.obj.S)
        this.A = Number(this.obj.A)
        for (let i = 0; i < Number(this.obj.S1) + 1; i++) {
          let obj = {
            code: i,
            value: `${i}个`
          }
          this.SList.push(obj)
        }
        for (let j = 0; j < Number(this.obj.A1) + 1; j++) {
          let obj = {
            code: j,
            value: `${j}个`
          }
          this.AList.push(obj)
        }
      } else {
        this.SList = []
        this.AList = []
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input__inner {
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0 10px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: $white;
}
/deep/.el-dialog__body {
  height: 350px;
}
</style>
