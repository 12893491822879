// 员工资料相关接口
import * as http from './http'

// 个人资料【盛一桉】
export const userdata = params => http.post('/user/data', params, { loading: false })
// 关注我的 郭红伟
export const attentionMy = params => http.post('/attention/attentionMy', params)
// 我的关注 郭红伟
export const myAttention = params => http.post('/attention/myAttention', params)
// 用户编辑【牛晓阳】
export const editUser = params => http.post('/user/edit-user', params)
// 上传文件、图片接口[多文件]【任晓军】
export const upload = params => http.post('/my/file/upload', params)
// 协议管理 pagelist【鲍红建】
export const getDetail = params => http.post('/public/agreement/getDetail', params)
// 意见反馈【鲍红建】
export const suggestionsave = params => http.post('/suggestion/save', params)
// 关于我们【李金辉】
export const about = params => http.post('/public/about', params)
// 首页等级下拉、个人主页 - 按用户ID + 年份，查询用户绩效等级【刘海宁】
export const uIdyear = params => http.post('/evalStaff/list/group/ym', params)
// APP-个人主页获取个人三清列表[宋红飞]
export const listreportinfo = params => http.post('/app/report-info/list-reportinfo', params, { interceptRes:false })
// 关注人展示列表【张贵亮】
export const focuslist = params => http.post('/target-home/focus/list', params)
// 个人主页【盛一桉】
export const homepage = params => http.post('/user/home/page', params)
// APP-三清点赞[宋红飞]
export const praise = params => http.post('/app/report-info/praise', params, { loading: false })
// APP-三清评论[宋红飞]
export const comment = params => http.post('/app/report-info/comment', params, { loading: false })
// APP-三清评论[宋红飞]
export const count = params => http.post('/attention/count', params, { loading: false })
