import * as http from './http'

// 民主互评-我的评价-收到的评价
export const mineReceiveList = (params, options) => http.post('/evaluate/three/receive/list', params, options)
// 民主互评收到评价 组织列表
export const organReceList = params => http.post('/evaluate/report/org', params)
// 组织列表全部数据
export const orgGroupList = params => http.post('/evaluate/report/orgGroup', params)

// 民主互评收到评价 个人列表
export const perReceList = params => http.post('/evaluate/report/user', params)

// 民主互评-我的评价-待我评价（批量）列表
export const mineEvaluateList = params => http.post('/evaluate/wait/list', params)

// 民主互评-我的评价-待我评价（批量）提交
export const minEvaluateSave = params => http.post('/evaluate/wait/batch/save', params)

// 民主互评-我的评价-我已评价
export const mineHasEvaluateList = (params, options) => http.post('/evaluated/three/list', params, options)

// 民主互评-下属的评价-下属互评
export const teamEvaluateList = (params, options) => http.post('/evaluated/team/list', params, options)

// 邀请人审批接口
export const inviteApprove = params => http.post('/evaluated/invite/approve', params)

// 邀请人详情
export const inviteDetail = params => http.post('/evaluated/invite/detail', params)

//邀请人提交保存
export const inviteSave = params => http.post('/evaluated/invite/save', params)

// 民主互评-团队互评 -待我审批列表
export const getWaitList = (params, options) => http.post('/evaluated/invite/waitList', params, options)

// 民主互评-团队互评 提交表单
export const submitApprove = (params, options) => http.post('/evaluated/invite/approve', params, options)

// 民主互评-团队互评 我已审批列表
export const getFinisList = (params, options) => http.post('/evaluated/invite/finishList', params, options)
