import { render, staticRenderFns } from "./organizeEvaluation.vue?vue&type=template&id=a3830fbe&scoped=true&"
import script from "./organizeEvaluation.vue?vue&type=script&lang=js&"
export * from "./organizeEvaluation.vue?vue&type=script&lang=js&"
import style0 from "./organizeEvaluation.vue?vue&type=style&index=0&id=a3830fbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3830fbe",
  null
  
)

export default component.exports