const state = {
  personList: [],
  userInfo: {},
  msgNumber: {
    backlogCount: 0, //待办消息数量
    processedCount: 0, //已办消息数量
    unreadCount: 0, //提醒消息数量
  },
  teamInfo: {}
}

export default state
