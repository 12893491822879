<template>
  <div class="result-infinite">
    <div class="result-box" v-for="(list, index) in lists" :key="index">
      <div class="personal-card-content">
        <div class="personal-item">
          <div class="line"></div>
          <span>{{ list.title || '' }}</span>
        </div>
        <div class="personal-middle">
          <div class="middle-left">
            <div class="num">{{ list.evaluate | formateVal }}</div>
            <div class="text">民主评议</div>
          </div>
          <div class="middle-right">
            <div class="add">
              <el-input-number
                v-model="list.target"
                v-filterZero
                @change="handleChange"
                :min="0"
                :max="max"
                :step="5"
                size="mini"
              ></el-input-number>
            </div>
            <div class="text">目标考评</div>
          </div>
          <div
            class="middle-right contribution"
            @click="handleItem(index, list.orgExtraScore, list.extraRemark)"
          >
            <div class="add special">
              <el-input
                v-model="list.orgExtraScore"
                @change="handleChange"
                placeholder="请选择"
                readonly
              >
              </el-input>
            </div>
            <div class="text">特别贡献</div>
          </div>
          <div class="box-right" :class="!list.pocRatio ? 'box-sel' : ''">
            <span class="text"
              >组织绩效{{ list.orgTotalScore | formateOrgTotalScore(list) }}分</span
            >
            <span class="grade" :class="'rank-' + list.pfLevel" v-if="list.pocRatio === 1">{{
              list.pfLevel
            }}</span>
            <div v-else class="grade sel">
              <el-select v-model="list.pfLevel" placeholder="" :name="list.pfLevel">
                <el-option
                  :class="'rank-' + item.val"
                  v-for="item in options"
                  :key="item.code"
                  :label="item.val"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="personal-bottom" @click="handleUser(list)">
          <div class="bottom-left">
            <div
              @click.stop="
                $router.push({ name: 'homepage-navigation', query: { userId: list.evalId } })
              "
            >
              <user-avatar
                :obj="{ avatar: list.evalAvatar, name: list.evalName }"
                :size="26"
                :font="12"
              />
            </div>
            <div
              class="text"
              @click.stop="
                $router.push({ name: 'homepage-navigation', query: { userId: list.evalId } })
              "
            >
              {{ list.evalName }}
            </div>
          </div>

          <div class="bottom-right">
            <template v-if="list.pocRatio === 1 && list.evalState === 1">
              <div class="text">个人绩效</div>
              <div class="level" :class="'rank-' + list.partakePfLevel">
                {{ list.partakePfLevel }}
              </div>
              <div class="grade">
                {{
                  list.staffTotalScore
                    | formateOrgTotalScore(list)
                    | formatePersonalTotalScore(list, benchmark, averageScore)
                }}分
              </div>
            </template>
            <template v-else>{{
              list.evalState | formateState(list.pocRatio, list.appointEvalName)
            }}</template>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <specialContribute
          :showDialog="showDialog"
          @valChange="handleData"
          :cIndex="itemIndex"
          :val="value"
          :remark="remark"
        />
      </div>

      <detail
        :showDialog="showDetailDialog"
        :userObj="userObj"
        :pageType="pageType"
        :benchmark="benchmark"
        :averageScore="averageScore"
      />
    </div>
  </div>
</template>

<script>
import specialContribute from '@/components/organizational/special-contribute'
import detail from '@/components/organizational/detail'
import userAvatar from '@/components/initiate/user-avatar'
import { scoreSum, formateNumber } from '@/utils/index.js'
export default {
  name: 'personal-itemB-card',
  components: {
    specialContribute,
    detail,
    userAvatar
  },
  props: {
    lists: {
      type: Array,
      default: () => {}
    },
    max: {
      type: Number,
      default: 0
    },
    benchmark: {
      type: Number,
      default: 0
    },
    averageScore: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isCompute: false,
      pageType: '',
      remark: '',
      value: 0,
      itemIndex: 0,
      userObj: {},
      count: 0,
      loading: false,
      options: [
        {
          code: 'S',
          val: 'S'
        },
        {
          code: 'A',
          val: 'A'
        },
        {
          code: 'B',
          val: 'B'
        },
        {
          code: 'C',
          val: 'C'
        }
      ],
      showDialog: false,
      showDetailDialog: false,
      num: 0
    }
  },
  filters: {
    formateState(code, pocRatio, name) {
      //  pocRatio：组织占比状态：0 - 否；1 - 是
      //  evalState 0非本组织考评  1正常  2试用期 离职不占比人员
      //  evalState 0 - 不占比；1 - 占比；2 - 非本组织；3 - 指定考评人
      //  appointEvalName：指定考评人（当evalState = 3）
      //list.evalState === 2 ? '试用期、离职、刚转正员工单独考评' : '不在本组织考评个人绩效'
      let str = ''
      let obj = {
        2: '不在本组织考评个人绩效',
        3: `已指定${name}考评`
      }
      str = obj[code]
      if (code === 0) {
        if (pocRatio === 1) {
          str = `试用期、离职、刚转正员工单独考评`
        } else {
          str = `不占强制分布比例需单独考评`
        }
      }
      if (code === 1) {
        if (!pocRatio) {
          str = `不占强制分布比例需单独考评`
        }
      }
      return str
    },
    formateVal(val) {
      return typeof val === 'object' ? '-' : `${val}分`
    },
    formateOrgTotalScore(val, list) {
      let { evaluate = 0, target = 0, orgExtraScore = 0, extraScore = 0 } = list
      let sum = scoreSum(evaluate, target, orgExtraScore, extraScore)
      return formateNumber(sum)
    },
    formatePersonalTotalScore(val, list, benchmark, averageScore) {
      let {
        rpmt = 0,
        satisfaction = 0,
        deduction = 0,
        innovate = 0,
        contribute = 0,
        partakeExtraScore = 0
      } = list
      let pageType =
        !list.pocRatio && (!list.evalState || list.evalState === 1) ? 'team' : 'organization'
      //目标分< 平均分（基准分）  减去创新-团贡
      let isCompute = benchmark && list.target < averageScore ? false : true
      if (!isCompute && pageType === 'organization') {
        innovate = 0
        contribute = 0
      }
      let sum = scoreSum(
        val,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        partakeExtraScore
      )
      return formateNumber(sum)
    }
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  methods: {
    handleItem(index, val, remark) {
      this.showDialog = true
      this.itemIndex = index
      this.value = val
      this.remark = remark
    },
    handleUser(item) {
      //  evalState 0 - 不占比；1 - 占比；2 - 非本组织；3 - 指定考评人
      //  appointEvalName：指定考评人（当evalState = 3）
      //  pocRatio 0 不占比  1 占比
      let obj = {
        2: '不在本组织考评个人绩效',
        3: `已指定${item.appointEvalName}考评`
      }
      //试用期 离职 刚转正员工单独考评
      if ([2, 3].includes(item.evalState)) {
        this.$warning(`${obj[item.evalState]}`)
        return false
      }
      if (!item.evalState && item.pocRatio) {
        this.$warning(`试用期、离职、刚转正员工单独考评`)
        return false
      }
      this.pageType =
        !item.pocRatio && (!item.evalState || item.evalState === 1) ? 'team' : 'organization'
      this.showDetailDialog = true

      let {
        orgTotalScore,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        partakeExtraScore,
        evalName,
        customize1,
        customize2,
        customize1Name,
        customize2Name,
        target,
        evaluate
      } = item
      //组织绩效分
      // //组织绩效分=组织负责人的目标分target+组织互评分+特别贡献加减分orgExtraScore-组织特别扣分extraScore-未按时完成组织互评的扣分(这个分数跟组织负责人的目标分同一个字段)
      orgTotalScore = scoreSum(evaluate, target, item.extraScore, item.orgExtraScore)
      this.userObj = {
        evalName,
        orgTotalScore,
        rpmt,
        satisfaction,
        deduction,
        innovate,
        contribute,
        partakeExtraScore,
        customize1,
        customize2,
        customize1Name,
        customize2Name,
        target,
        evaluate
      }
    },
    errorHandler() {
      return true
    },
    handleData(index, val, remark) {
      if (this.$parent.resultList[index].orgExtraScore !== val) {
        //extraRemark
        this.$emit('handleFlag', false)
      }
      this.$parent.resultList[index].orgExtraScore = val
      this.$parent.resultList[index].extraRemark = remark
    },
    handleChange(value) {
      this.$emit('handleFlag', false)
      //目标分<平均分(基准分)
      // this.isCompute = this.benchmark && value < this.averageScore ? false : true
    }
  }
}
</script>

<style lang="scss" scoped>
.result-infinite {
  flex: 1;
  overflow-y: scroll;
  margin-left: 70px;
  .nodata {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 14px;
    text-align: center;
    margin-top: 30px;
  }
  .result-box {
    margin-left: 15px;
    padding: 20px 0px 15px 0px;
    box-shadow: 0px 1px 0px 0px #eeeeee;
    display: flex;
  }
}
.personal-card-content {
  width: 100%;
  .personal-item {
    display: flex;
    flex: 1;
    margin-bottom: 17px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: $lightblack;
    line-height: 16px;
    .line {
      width: 2px;
      height: 16px;
      background: #3d7eff;
      margin-right: 10px;
    }
  }
  .personal-middle {
    display: flex;
    margin-left: 29px;
    align-items: center;
    .middle-left {
      text-align: center;
      margin-right: 60px;
      .num {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: $lightblack;
        line-height: 16px;
      }
      .text {
        margin-top: 15px;
        font-size: 12px;

        font-weight: 400;
        color: $grey;
        line-height: 12px;
      }
    }
    .middle-right {
      text-align: center;
      .add {
        width: 95px;
        //height: 26px;
      }
      .text {
        margin-top: 10px;
        font-size: 12px;

        font-weight: 400;
        color: $grey;
        line-height: 12px;
      }
      /deep/.el-input-number--mini {
        width: 108px;
        box-sizing: border-box;
        height: 26px;
      }
      /deep/.el-input__inner {
        font-size: 16px;
        font-weight: 400;
        color: $lightblack;
        line-height: 16px;
        border-radius: 0px;
        box-sizing: border-box;
        border: 1px solid #aaaaaa;
      }
      /deep/.el-input__suffix {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /deep/.el-input-number__decrease,
      /deep/.el-input-number__increase {
        width: 26px;
        background: $white;
      }
      /deep/.el-input-number__increase {
        border-left: 1px solid #aaaaaa;
      }
      /deep/.el-input-number__decrease {
        border-right: 1px solid #aaaaaa;
      }
      /deep/.el-input-number--mini .el-input__inner {
        padding-left: 12px;
        padding-right: 12px;
      }
      .special {
        /deep/.el-input__inner {
          height: 28px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .contribution {
      margin-left: 60px;
      cursor: pointer;
    }
    .box-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;
      margin-right: 29px;
      .text {
        font-size: 14px;

        font-weight: 400;
        color: $darkgrey;
        line-height: 14px;
      }
      .grade {
        margin-left: 10px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: $darkgrey;
        line-height: 20px;
      }
      .sel {
        /deep/.el-input__inner {
          width: 66px;
          box-sizing: border-box;
          height: 26px;
        }
        /deep/.el-input__inner {
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          color: $lightblack;
          line-height: 16px;
          border-radius: 0px;
          box-sizing: border-box;
          border: 1px solid #aaaaaa;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
    }
    .box-sel {
      margin-right: 15px;
    }
  }
  .personal-bottom {
    margin-top: 15px;
    margin-right: 15px;
    height: 40px;
    background: #f6f6f6;
    border-radius: 3px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .bottom-left {
      display: flex;
      align-items: center;
      // .bottom-img {
      //   width: 22px;
      //   height: 22px;
      // }
      .text {
        margin-left: 5px;
        font-size: 12px;

        font-weight: 400;
        color: $lightblack;
        line-height: 12px;
      }
    }
    .bottom-right {
      margin-right: 15px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;

      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
      .text {
        margin-right: 5px;
      }
      .grade {
        margin-left: 15px;
        margin-right: 10px;
      }
      .level {
        color: $darkgrey;
      }
    }
  }
  .rank-B {
    color: $darkgrey !important;
  }
  .rank-C {
    color: $red !important;
  }
  .rank-S {
    color: $C89557 !important;
  }
  .rank-A {
    color: $blue !important;
  }
  .sel {
    //#FE831A
    /deep/.el-select__caret {
      color: $yellow !important;
    }
    /deep/.el-input__inner {
      border: 1px solid $yellow !important;
      background: #fff9f3 !important;
      font-size: 20px !important;
      font-weight: 500 !important;
    }
    /deep/.el-input__inner[name='S'] {
      color: $C89557 !important;
    }
    /deep/.el-input__inner[name='A'] {
      color: $blue !important;
    }
    /deep/.el-input__inner[name='B'] {
      color: $darkgrey !important;
    }
    /deep/.el-input__inner[name='C'] {
      color: $red !important;
    }
  }
}
</style>
