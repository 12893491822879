<template>
  <div class="explanation">
    <el-dialog
      append-to-body
      :visible.sync="showTip"
      v-if="showTip"
      title="确认考评结果"
      center
      :before-close="handleClose"
      custom-class="appeal-dialog"
    >
      <div class="dialog-main">
        <div v-if="pageType === 'organization'">
          <div class="list-title">组织考评</div>
          <div class="box" v-for="(item, index) in orgList" :key="index">
            <div class="title">{{ item.orgName }}</div>
            <div class="value" :class="item.pfLevel">{{ item.pfLevel }}</div>
          </div>
        </div>

        <div class="list-title user" v-if="pageType === 'organization'">组织负责人考评</div>

        <div class="box person" v-for="(items, indexs) in userList" :key="indexs">
          <user-avatar
            :size="26"
            :font="12"
            :obj="{ avatar: items.evalAvatar, name: items.evalName }"
          />
          <div class="title">{{ items.evalName }}</div>
          <div class="org">{{ items.orgName || '' }}</div>
          <div class="value" :class="items.partakePfLevel">{{ items.partakePfLevel }}</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">再改改</el-button>
          <el-button type="primary" @click="handleConfirm">确认提交</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import '@/components/democracy/edit-dialog.scss'
import userAvatar from '@/components/initiate/user-avatar'
export default {
  name: 'organization-submit-lst',
  props: {
    code: {
      type: Number,
      default: 99 //9 强制完成目标考评 99正常提交
    },
    pageType: {
      type: String,
      default: 'personal' //personal 个人 organization 组织 depart 试用期
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    orgList: {
      type: Array,
      default: () => []
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    userAvatar
  },
  data() {
    return {
      showTip: this.showDialog
    }
  },
  methods: {
    errorHandler() {
      return true
    },
    handleConfirm() {
      this.$parent.handleEvalOrgSave(this.code)
    },
    async handleClose() {
      this.showTip = false
      this.$parent.showDialog = false
      if (this.pageType === 'organization') {
        this.$parent.orgList = []
      }
      this.$parent.userList = []
    }
  },
  watch: {
    showDialog(val) {
      this.showTip = this.showDialog
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input__inner {
  border: none;
  border-bottom: 1px solid #eeeeee;
  padding: 0 10px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: $white;
}
/deep/.el-dialog--center .el-dialog__body {
  padding: 0 30px;
  height: 350px;
  overflow-y: scroll;
}
/deep/.el-dialog__footer {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.list-title {
  margin-top: 18px;
  font-size: 14px;
  font-weight: 500;
  color: $lightblack;
  line-height: 16px;
}
.user {
  margin-top: 28px;
}
.box {
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 0px 0px $linecolor;
  padding: 18px 0px;
  font-size: 14px;
  font-weight: 400;
  color: $darkgrey;
  line-height: 14px;
  align-items: center;
  .title {
    margin-left: 8px;
  }
  .org {
    margin-left: 8px;
    color: $grey;
  }
  .value {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: $darkgrey;
  }
  .C {
    color: $red;
  }
  .S {
    color: $C89557;
  }
  .A {
    color: $blue;
  }
}
.person {
  padding: 12px 0;
}
</style>
