import { count } from '@/api/employeedata.js'

const modulePersoncenter = {
  state: () => ({
    countData: []
  }),

  mutations: {
    setCountData(state, data) {
      state.countData = data
    }
  },

  actions: {
    async loadCountData({ commit }) {
      let _data = {}
      const { code, data } = await count()
      if (code === '000000') {
        _data = data
      }
      commit('setCountData', _data)
    }
  },
}

export default modulePersoncenter
