import { mineRed, teamRed } from '@/api/api_satisfaction'
import { satisfactionNav } from '@/config/nav'
import { MineTabNames, SatisfactionUnderlingsTabNames } from '@/components/democracy/state.js'

const moduleStatisfaction = {
  state: () => ({
    mineTabs: [],
    teamTabs: []
  }),

  mutations: {
    setMineTabs(state, data) {
      state.mineTabs = data
    },
    setTeamTabs(state, data) {
      state.teamTabs = data
    }
  },

  actions: {
    /**
     * 获取我的满意度-红点数据
     */
    async loadMineRed({ commit }) {
      let _tabs = MineTabNames
      const { code, data } = await mineRed()
      if (code === '000000') {
        const _redData = {
          comment: data?.evaluateNum ?? 0
        }
        _tabs = MineTabNames.map(tab => {
          tab.badge = _redData[tab.name] ?? 0
          return tab
        })
      }
      commit('setMineTabs', _tabs)
    },

    /**
     * 获取团队满意度-红点数据
     */
    async loadTeamRed({ commit }) {
      let _tabs = SatisfactionUnderlingsTabNames
      const { code, data } = await teamRed()
      if (code === '000000') {
        const _redData = {
          approve: data?.auditNum ?? 0, // 待我审批数量
          complaint: data?.complaintNum ?? 0, // 投诉处理数量
          touch: data?.touchNum ?? 0 // 感动处理数量
        }
        _tabs = SatisfactionUnderlingsTabNames.map(tab => {
          tab.badge = _redData[tab.name] ?? 0
          return tab
        })
      }
      commit('setTeamTabs', _tabs)
    }
  },

  getters: {
    navs(state) {
      satisfactionNav.children[0].badge = state.mineTabs
        .map(m => m.badge ?? 0)
        .reduce((total, cur) => {
          return total + cur
        }, 0)

      satisfactionNav.children[1].badge = state.teamTabs
        .map(m => m.badge ?? 0)
        .reduce((total, cur) => {
          return total + cur
        }, 0)

      return satisfactionNav.children
    }
  }
}

export default moduleStatisfaction
