import Vue from 'vue'
import {
  Button as ElButton,
  Breadcrumb as ElBreadcrumb,
  BreadcrumbItem as ElBreadcrumbItem,
  Tabs,
  TabPane,
  Select as ElSelect,
  Option as ElOption,
  Switch,
  Divider,
  Avatar,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  Calendar,
  Menu,
  MenuItem,
  Progress,
  InfiniteScroll as ElInfiniteScroll,
  Radio as ElRadio,
  RadioButton as ElRadioButton,
  RadioGroup as ElRadioGroup,
  Dialog,
  Input,
  Form,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  FormItem,
  InputNumber,
  Row,
  Col,
  Timeline,
  TimelineItem,
  Upload,
  Table,
  TableColumn,
  Tabs as ElTabs,
  TabPane as ElTabPane,
  Pagination as ElPagination,
  Rate as ElRate,
  Avatar as ElAvatar,
  Input as ElInput,
  Divider as ElDivider,
  Popconfirm as ElPopconfirm,
  Tag as ElTag,
  Tree,
  Loading,
  Popover
} from 'element-ui'
import './element-variables.scss'
Vue.use(ElButton)
Vue.use(ElBreadcrumb)
Vue.use(ElBreadcrumbItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(ElSelect)
Vue.use(ElOption)
Vue.use(Switch)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Divider)
Vue.use(ElInfiniteScroll)
Vue.use(ElRadio)
Vue.use(ElRadioButton)
Vue.use(ElRadioGroup)
Vue.use(Avatar)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(ElTabs)
Vue.use(ElTabPane)
Vue.use(ElPagination)
Vue.use(ElRate)
Vue.use(ElAvatar)
Vue.use(ElInput)
Vue.use(ElDivider)
Vue.use(ElPopconfirm)
Vue.use(InputNumber)
Vue.use(Calendar)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tooltip)
Vue.use(Progress)
Vue.use(ElTag)
Vue.use(Row)
Vue.use(Col)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Upload)
Vue.use(Tree)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(Popover)
