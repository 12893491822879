<template>
  <div id="app">
    <router-view v-if="$route.meta.routerRefresh" :key="key"/>
    <router-view v-else/>
  </div>
</template>
<script>
// 入口组件
export default {
  name: 'App',
  computed:{
    key(){
      return this.$route.path+Date.now()
    }
  },
  created() {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  }
}
</script>
