<template>
  <div class="personal-result-view">
    <div class="result-main" v-loading="loading">
      <div class="main-top">
        <div class="content-left__wrap" v-if="date">
          <span class="content-left__time">{{ date | formateVal(1) }}</span>
          <i class="content-left__unit">{{ date | formateVal(2) }}</i>
        </div>
        <result-footer pageType="organization" :list="levelList" :obj="{ name: name, count: countSum }" :levelData="levelData" model="B" />
        <div class="buttons">
          <div class="num" @click="showPassDialog = true">
            <i class="iconfont icontz"></i>
            <span class="refresh">调整本月S/A数量</span>
          </div>
          <div class="rank" @click="refreshRank">
            <i class="el-icon-refresh"></i>
            <span class="refresh">刷新排名</span>
          </div>
        </div>
      </div>
      <personal-item-card :lists="resultList" :max="max" @handleFlag="handleFlag" :benchmark="benchmark" :averageScore="parseFloat(averageScore)" />
    </div>
    <div class="result-bottom" @click="handleSubmit">
      <el-button type="primary" class="blue">提交考评</el-button>
    </div>
    <!-- S1 A1最大值 S A 当前选中个数 -->
    <count-sel
      :showDialog="showPassDialog"
      :obj="{
        S1: this.Srange,
        A1: this.Arange,
        S: levelList[0].big,
        A: levelList[1].big,
        count: count,
        C: levelList[3].big
      }"
      @resetTopdata="handleTopData"
    />
    <submit-list :code="code" :showDialog="showDialog" :orgList="orgList" pageType="organization" :userList="userList" />
  </div>
</template>
<script>
import resultFooter from '@/components/organizational/result-footer'
import personalItemCard from '@/components/organizational/personal-itemC-card.vue'
import { evalOrg, getEvalOrgTo, postEvalOrgSave } from '@/api/api_organization'
import countSel from '@/components/organizational/count-sel'
import submitList from '@/components/organizational/organization-submit-list'
import { MessageBox, Message } from 'element-ui'
import { scoreSum, formateNumber } from '@/utils/index.js'
import { sumMixin } from '@/mixins/organize/evaluationMixin.js'
export default {
  mixins: [sumMixin],
  name: 'organize-evaluation',
  components: {
    resultFooter,
    personalItemCard,
    countSel,
    submitList
  },
  data() {
    return {
      countSum: 0
    }
  },
  methods: {
    handleSubmit() {
      if (this.resultList.length < 1) {
        return false
      }
      if (this.resultList.length < 2) {
        this.isSort = true
      }
      if (!this.isSort) {
        MessageBox.confirm(`分数调整后尚未刷新排名，请先刷新排名`, '提示', {
          confirmButtonText: `确定`,
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.isSort = true
            this.handleResetSort()
          })
          .catch(() => {
            Message.info(`已取消操作`)
          })
      } else {
        this.handleResetSort()
        this.handleEvalOrgSave()
      }
    },
    handleEvalOrgSave(code = 1) {
      let orgSurplus = []
      this.levelList.forEach(el => {
        let org = { level: el.level, use: el.big }
        orgSurplus.push(org)
      })
      let params = {
        date: this.date,
        cfgId: this.cfgId,
        orgId: this.orgId,
        orgSurplus: orgSurplus, //等级结余
        partakeOrgs: this.resultList,
        state: code, //1 正常提交 99完成确认结果 9强制,
        benchmarkScore: this.benchmark ? this.averageScore : null
      }
      postEvalOrgSave(params)
        .then(res => {
          if (res.code === '000000') {
            if (code === 1) {
              //第一次提交
              this.handleList()
              this.code = 99
            } else if (code === 99) {
              //第二次提交 确认提交
              this.$msgSuccess('组织绩效考评成功')
              this.showDialog = false
              this.orgList = []
              this.userList = []
              this.$router.push({ name: 'appraisal' })
            } else if (code === 9) {
              //  强制提交后，再次弹出确认提交
              this.handleList()
              this.code = 99
            }
          } else if (res.code === '051507') {
            //提交考评后数据有更新
            MessageBox.confirm(`部分成员的绩效总分后台有更新`, '提示', {
              confirmButtonText: `立即刷新排名`,
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.getList()
                if (code === 99) {
                  this.showDialog = false
                }
              })
              .catch(() => {
                Message.info(`已取消操作`)
              })
          } else if (res.code === '051504') {
            //目标考评未完成
            MessageBox.confirm(`部分成员目标考评尚未完成，强制完成目标考评并继续?`, '提示', {
              confirmButtonText: `确定`,
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(() => {
                this.handleEvalOrgSave(9)
              })
              .catch(() => {
                Message.info(`已取消操作`)
              })
          } else {
            this.$msgError(res.msg || '接口异常')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleList() {
      this.showDialog = true
      this.orgList = []
      this.userList = []
      this.resultList.forEach(el => {
        let { orgName, pfLevel, evalName, evalAvatar, partakePfLevel, title } = el
        let org = {
          orgName: title,
          pfLevel
        }
        let user = {
          evalName,
          evalAvatar,
          partakePfLevel
        }
        this.orgList.push(org)
        this.userList.push(user)
      })
    },
    handleTopData(S, A) {
      this.handleTopDataFn(S, A)
      this.handleResetSort()
    },
    /**
     * 列表
     */
    getList() {
      this.getTopOrgList().then(resData => {
        let {
          levels,
          targetMaxScore,
          benchmark: { rule, benchmarkScore }
        } = resData.data
        //rule 0 无限制 [1,2=平均分] [3,4=基准分]
        //benchmarkScore 基准分
        this.benchmark = rule
        this.benchmarkScore = benchmarkScore
        let params = {
          // date: this.date,
          date: this.date,
          // orgId: this.orgId,
          orgId: this.orgId,
          // eocId: this.cfgId
          eocId: this.cfgId
        }
        this.loading = true
        if (!this.benchmark) {
          this.benchmarkScore = null
        }
        getEvalOrgTo(params)
          .then(res => {
            this.countSum = res.data ? res.data.length : 0
            let count = res.data.filter(el => el.pocRatio).length
            this.count = count || 0
            //计算头部结余，对应的等级数
            this.handleSum(levels, targetMaxScore)
            //排序（先看个人排序，赋值等级，然后按组织排序，赋值组织等级）
            //根据组织占不占比过滤数据

            this.resultList = res.data ? res.data : []
            this.resultList.forEach(el => {
              this.$set(el, 'extraRemark', el.extraRemark || '')
              this.$set(el, 'target', parseFloat(el.target || 0))
            })
            this.handleResetSort()
          })
          .finally(() => (this.loading = false))
      })
    },
    handleResetSort() {
      //计算组织总分 个人总分
      this.resultList.forEach(el => {
        let {
          evaluate = 0,
          target = 0,
          extraScore = 0,
          orgExtraScore = 0,
          rpmt = 0,
          satisfaction = 0,
          deduction = 0,
          innovate = 0,
          contribute = 0,
          partakeExtraScore = 0,
          customize1 = 0,
          customize2 = 0
        } = el
        if (typeof target !== 'object') {
          if (target > this.max) {
            target = this.max
          }
        }
        //组织绩效分
        let orgTotalScore = 0
        // //组织绩效分=组织负责人的目标分target+组织互评分+特别贡献加减分orgExtraScore-组织特别扣分extraScore-未按时完成组织互评的扣分(这个分数跟组织负责人的目标分同一个字段)
        orgTotalScore = scoreSum(evaluate, target, extraScore, orgExtraScore)
        let staffTotalScore = scoreSum(orgTotalScore, rpmt, satisfaction, deduction, innovate, contribute, partakeExtraScore, customize1, customize2)
        //如果不占比，不加组织绩效分
        if (!el.pocRatio && (!el.evalState || el.evalState === 1)) {
          staffTotalScore = staffTotalScore - orgTotalScore + target + evaluate
        }
        //组织负责人个人绩效分=组织绩效分+三清两会/客户满意(不包括内部满意度分)/创新成果/团队贡献/行为规范等分数-后台按月手动特别扣分
        //组织绩效+三清两会
        this.$set(el, 'orgTotalScore', formateNumber(orgTotalScore))

        this.$set(el, 'staffTotalScore', formateNumber(staffTotalScore))
      })
      //占比组织
      let leftArr = this.resultList.filter(el => el.pocRatio)
      //不占比组织
      let rightArr = this.resultList.filter(el => !el.pocRatio)
      //过滤出不在本组织考评的人
      let sumB = leftArr.filter(el => el.evalState !== 1)
      //B=B-不在本组织考评的人,如果为负数，B=0
      let reduceB = 0
      if (sumB && sumB.length > 0) {
        reduceB = this.levelList[2].big - sumB.length < 0 ? 0 : this.levelList[2].big - sumB.length
      } else {
        reduceB = this.levelList[2].big
      }
      //将等级数放到一个数组里
      let levels = this.handleLevels(reduceB)
      //摘出在本组织的人，赋值levels
      let userData = leftArr.filter(el => el.evalState === 1)
      //配置规则  0 无限制 [1,2=平均分] [3,4=基准分]
      // this.averageScore = this.handleAverageScore(this.benchmark, this.benchmarkScore, userData)
      this.averageScore = this.resultList[0] ? this.resultList[0].benchmarkScore : 0
      let cCount = levels.filter(el => el === 'C').length
      this.resultList.forEach(el => {
        if (el.sumOfTarget == 0) {
          userData.sort(this.createComprisonFunction('staffTotalScore')) //总分
        } else {
          userData.sort(this.createComprisonFunction('target')) //工作目标分
        }
      })

      userData.forEach((el, index) => {
        //当人员<组织，要把C强制用了
        if (userData.length - index <= cCount) {
          this.$set(el, 'partakePfLevel', 'C')
        } else {
          this.$set(el, 'partakePfLevel', levels[index])
        }
      })
      leftArr.forEach((el, index) => {
        //不在本组织考评的人,等级赋值，占位
        if (el.evalState !== 1) {
          this.$set(el, 'partakePfLevel', '')
        }
        let isCompute = this.benchmark && el.target < this.averageScore ? false : true
        //目标分< 平均分（基准分）  减去创新-团贡
        if (!isCompute) {
          let { innovate, contribute } = el
          innovate = 0
          contribute = 0
          let sum = el.staffTotalScore - innovate - contribute
          this.$set(el, 'staffTotalScore', sum)
        }
      })
      //组织排序 赋值
      let levelsBig = this.handleLevels(this.levelList[2].big)
      leftArr.sort(this.createComprisonFunction('staffTotalScore'))
      leftArr.sort(this.createComprisonFunction('orgTotalScore'))
      leftArr.forEach((el, index) => {
        this.$set(el, 'pfLevel', levelsBig[index])
      })
      //不占比组织，重新排序
      // rightArr.sort(this.createComprisonFunction('staffTotalScore'))
      rightArr.sort(this.createComprisonFunction('orgTotalScore'))
      rightArr.forEach(el => {
        this.$set(el, 'pfLevel', el.pfLevel || 'B')
      })
      this.resultList = []
      this.resultList = [...leftArr, ...rightArr]
    },
    /**
     * 刷新排名
     */
    refreshRank() {
      this.handleResetSort()
      this.isSort = true
    },
    /**
     * 头部百分比+上月结余信息
     * /eval/org
     */
    async getTopOrgList() {
      let params = {
        date: this.date,
        orgId: this.orgId,
        eocId: this.cfgId
      }
      return await evalOrg(params)
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/organize.scss';
</style>
