<template>
  <div class="tojoy-write-dayContent">
    <div class="threeDown">
      <div class="resTitle">
        <span class="resName">{{ title }}</span>
      </div>
      <div class="box">
        <el-form inline-message :show-message="true" :rules="rules" label-position="top" ref="workForm" :model="workForm" class="reset-form">
          <div class="form-list">
            <!-- 汇报对象 -->
            <div class="item" v-if="formListState.reportObject.isEnabled === 1">
              <el-form-item
                :label="formListState.reportObject.showName"
                prop="reportObject"
                :rules="[{ required: formListState.reportObject.isRequired === 1 ? true : false, message: '请选择' + formListState.reportObject.showName, trigger: 'change' }]"
              >
                <choose-people
                  :placeholder="'请选择'"
                  :disableDataList="disableDataList"
                  :multipleLimit="10"
                  :reserveKeyword="false"
                  v-model="workForm.reportObject"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                ></choose-people>
              </el-form-item>
            </div>
            <!-- 抄送人 -->
            <div class="item" v-if="formListState.copyObject.isEnabled === 1">
              <el-form-item
                :label="formListState.copyObject.showName"
                prop="copyObject"
                :rules="[{ required: formListState.copyObject.isRequired === 1 ? true : false, message: '请选择' + formListState.copyObject.showName, trigger: 'change' }]"
              >
                <choose-people
                  :placeholder="'请选择'"
                  :disableDataList="disableDataList"
                  :multipleLimit="100"
                  :reserveKeyword="false"
                  v-model="workForm.copyObject"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                ></choose-people>
              </el-form-item>
            </div>
          </div>
          <div class="form-list">
            <!-- 今日工作成果 -->
            <div class="item" v-if="formListState.workContent.isEnabled === 1">
              <el-form-item
                :rules="[{ required: formListState.workContent.isRequired === 1 ? true : false, message: formListState.workContent.showName + '不能为空', trigger: 'blur' }]"
                :label="!makeUp ? formListState.workContent.showName : title"
                prop="workContent"
              >
                <div class="nextplan" @click="syncLastPlan"><i class="el-icon-refresh"></i>{{ btnworkVal }}</div>
                <el-input
                  :show-word-limit="true"
                  v-model="workForm.workContent"
                  :maxlength="Number(reportType) === 1 ? 800 : 60000"
                  resize="none"
                  v-filteremoji
                  class="pleaseWrite"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 16 }"
                  :placeholder="`请填写工作成果\n1、...\n2、...\n3、...`"
                >
                </el-input>
              </el-form-item>
            </div>
            <!-- 经验分享 -->
            <div class="item" v-if="formListState.experienceShare.isEnabled === 1">
              <el-form-item
                :label="formListState.experienceShare.showName"
                :rules="[
                  { min: 0, max: 800, message: formListState.experienceShare.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.experienceShare.isRequired === 1 ? true : false, message: formListState.experienceShare.showName + '不能为空', trigger: 'blur' }
                ]"
                prop="experienceShare"
              >
                <el-input
                  maxlength="800"
                  :show-word-limit="true"
                  resize="none"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  class="pleaseWrite"
                  placeholder="请输入"
                  v-model="workForm.experienceShare"
                  clearable
                />
              </el-form-item>
            </div>
            <!-- 未完成工作及原因 -->
            <div class="item" v-if="formListState.undoWork.isEnabled === 1">
              <el-form-item
                :label="formListState.undoWork.showName"
                :rules="[
                  { min: 1, max: 800, message: formListState.undoWork.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.undoWork.isRequired === 1 ? true : false, message: formListState.undoWork.showName + '不能为空', trigger: 'blur' }
                ]"
                prop="undoWork"
              >
                <el-input
                  maxlength="800"
                  resize="none"
                  :show-word-limit="true"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  class="pleaseWrite"
                  :placeholder="'请输入' + formListState.undoWork.showName"
                  v-model="workForm.undoWork"
                  clearable
                />
              </el-form-item>
            </div>
            <!-- 主要问题和改进措施 -->
            <div class="item" v-if="formListState.problem.isEnabled === 1">
              <el-form-item
                :rules="[
                  { min: 0, max: 800, message: formListState.problem.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.problem.isRequired === 1 ? true : false, message: formListState.problem.showName + '不能为空', trigger: 'blur' }
                ]"
                :label="formListState.problem.showName"
                prop="problem"
              >
                <el-input
                  maxlength="800"
                  resize="none"
                  :show-word-limit="true"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  class="pleaseWrite"
                  :placeholder="'请输入' + formListState.problem.showName"
                  v-model="workForm.problem"
                  clearable
                />
              </el-form-item>
            </div>
            <!-- 创新提案 -->
            <div class="item" v-if="formListState.newIdea.isEnabled === 1">
              <el-form-item
                :rules="[
                  { min: 0, max: 800, message: formListState.newIdea.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.newIdea.isRequired === 1 ? true : false, message: formListState.newIdea.showName + '不能为空', trigger: 'blur' }
                ]"
                :label="formListState.newIdea.showName"
                prop="newIdea"
              >
                <el-input
                  maxlength="800"
                  resize="none"
                  :show-word-limit="true"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  :placeholder="'请输入' + formListState.newIdea.showName"
                  v-model="workForm.newIdea"
                  clearable
                />
              </el-form-item>
            </div>
            <!-- 行为规范月度自评 -->
            <div class="item" v-if="formListState.deductionSelf.codeName === 'deductionSelf' && formListState.deductionSelf.isEnabled === 1">
              <el-form-item
                :rules="[{ required: formListState.deductionSelf.isRequired === 1 ? true : false }]"
                :show-message="false"
                :label="formListState.deductionSelf.showName"
                prop="deductionSelf"
              >
                <p v-if="formListState.deductionSelf.isEnabled === 1" :class="['conduct', isDeductionSelf ? '' : 'red']" @click="toBehaviour">
                  <el-input v-model="workForm.deductionSelf"></el-input>
                </p>
              </el-form-item>
            </div>
          </div>
          <div v-if="formListState.selfFullState.isEnabled === 1 || formListState.selfPeakState.isEnabled === 1 || formListState.selfValueState.isEnabled === 1" class="tommory">
            <span class="scoreTitle">自我评价</span
            ><span class="scoreSpe star" v-if="formListState.selfFullState.isRequired === 1 || formListState.selfPeakState.isRequired === 1 || formListState.selfValueState.isRequired === 1">*</span
            ><span class="scoreNum">每项满分100分</span>
          </div>
          <div class="form-list bottomNone">
            <div class="ownDiv">
              <!-- 巅峰状态工作 -->
              <p class="ownPline" v-if="formListState.selfPeakState.isEnabled === 1">
                <span class="topStatu">{{ formListState.selfPeakState.showName }}</span>
                <el-radio-group v-model="workForm.selfPeakState" size="medium">
                  <el-radio-button border v-for="(items, index) in score" :label="items" :key="index">{{ items }}</el-radio-button>
                </el-radio-group>
              </p>
              <!-- 满负荷工作 -->
              <p class="ownPline" v-if="formListState.selfFullState.isEnabled === 1">
                <span class="topStatu">{{ formListState.selfFullState.showName }}</span>
                <el-radio-group v-model="workForm.selfFullState" size="medium">
                  <el-radio-button border v-for="(items, index) in score" :label="items" :key="index">{{ items }}</el-radio-button>
                </el-radio-group>
              </p>
              <!-- 每刻的工作都最有价值 -->
              <p class="ownPline" v-if="formListState.selfValueState.isEnabled === 1">
                <span class="topStatu">{{ formListState.selfValueState.showName }}</span>
                <el-radio-group v-model="workForm.selfValueState" size="medium">
                  <el-radio-button border v-for="(items, index) in score" :label="items" :key="index">{{ items }}</el-radio-button>
                </el-radio-group>
              </p>
            </div>
          </div>
          <!-- 明日工作计划 -->
          <div class="tommory" v-if="formListState.workPlan.isEnabled === 1">
            <span class="scoreTitle">{{ formListState.workPlan.showName }}</span
            ><span class="scoreSpe star" v-if="formListState.workPlan.isRequired === 1">*</span><span v-if="formListState.workPlan.isRequired === 1" class="scoreNum">至少填写一项</span>
          </div>
          <div v-if="formListState.workPlan.isEnabled === 1" class="form-list bottomNone">
            <div class="telldivList" v-for="(item, index) in importStatu" :key="index">
              <p class="tell">{{ item.text }}</p>
              <el-input
                :maxlength="800"
                resize="none"
                :show-word-limit="true"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
                v-filteremoji
                class="pleaseWriteTell"
                placeholder="请输入"
                v-model="item.val"
                clearable
              />
            </div>
          </div>
          <div class="form-list">
            <div class="item" v-if="formListState.safeguards.isEnabled === 1">
              <!-- 保障措施 -->
              <el-form-item
                :rules="[
                  { min: 1, max: 800, message: formListState.safeguards.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.safeguards.isRequired === 1 ? true : false, message: formListState.safeguards.showName + '不能为空', trigger: 'blur' }
                ]"
                :label="formListState.safeguards.showName"
                prop="safeguards"
              >
                <el-input
                  maxlength="800"
                  :show-word-limit="true"
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  class="pleaseWriteTell"
                  :placeholder="'请输入' + formListState.safeguards.showName"
                  v-model="workForm.safeguards"
                  clearable
                />
              </el-form-item>
            </div>
            <div class="item" v-if="formListState.needPower.isEnabled === 1">
              <!-- 需要赋能 -->
              <el-form-item
                :rules="[
                  { min: 0, max: 800, message: formListState.needPower.showName + '限800字以内', trigger: 'blur' },
                  { required: formListState.needPower.isRequired === 1 ? true : false, message: formListState.needPower.showName + '不能为空', trigger: 'blur' }
                ]"
                :label="formListState.needPower.showName"
                prop="needPower"
              >
                <el-input
                  resize="none"
                  maxlength="800"
                  :show-word-limit="true"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 4 }"
                  v-filteremoji
                  class="pleaseWriteTell"
                  :placeholder="'请输入' + formListState.needPower.showName"
                  v-model="workForm.needPower"
                  clearable
                />
              </el-form-item>
            </div>
          </div>

          <!-- 自定义事项 -->
          <div class="tommory bottomNone" v-if="formListState.isSelf.isEnabled === 1">
            <div v-for="(item, index) in selfInputList" :key="index">
              <div class="scoreLine delete">
                <span class="scoreTitle">自定义事项</span>
                <span @click="deleteSelfList(index)" class="el-icon-delete"> 删除</span>
              </div>
              <div class="form-list bottomNone">
                <div class="resDiv">
                  <div class="telldivList" v-for="(ite, inde) in item.template" :key="inde">
                    <p class="tell">{{ ite.text }}</p>
                    <el-input
                      maxlength="800"
                      resize="none"
                      :show-word-limit="true"
                      type="textarea"
                      :autosize="{ minRows: 1, maxRows: 4 }"
                      class="pleaseWriteTell"
                      placeholder="请输入内容"
                      v-model="ite.val"
                      clearable
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <!-- 添加自定义按钮 -->
        <div class="addPlan" v-if="formListState.isSelf.isEnabled === 1" @click="addSelfInputList"><i class="el-icon-circle-plus-outline"></i>添加自定义事项</div>
      </div>
      <div class="saveBox">
        <el-button class="subButton" v-if="!makeUp" :loading="saveBtnStatu" @click="reportClickStage(1)">保存为草稿</el-button>
        <el-button class="subButton" type="primary" :loading="subBtnStatu" @click="reportClickStage(2)">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui'
import ChoosePeople from '../../components/common/tojoy-select/person-selected.vue'
import { save, reportDraftDetail, synchroplan, formConfig, fetchReportCheck, repairSave, submitState } from '@/api/thrclear.js'
export default {
  name: 'thrclear-work-day',
  data() {
    let workquery = ''
    let nextworkquery = ''
    if (this.$route.query.reportType == 1) {
      workquery = '今日'
      nextworkquery = '明日'
    } else if (this.$route.query.reportType == 2) {
      workquery = '本周'
      nextworkquery = '下周'
    } else if (this.$route.query.reportType == 3) {
      workquery = '本月'
      nextworkquery = '下月'
    }
    return {
      btnworkVal: '',
      isDeductionSelf: null,
      disableDataList: [],
      subThreeClearStatu: false,
      subBtnStatu: false,
      saveBtnStatu: false,
      workValueStatu: workquery,
      nextWorkeVal: nextworkquery,
      reportType: 0,
      formListState: {
        reportObject: {
          isEnabled: 1
        },
        copyObject: {
          isEnabled: 1
        },
        workContent: {
          isEnabled: 1
        },
        experienceShare: {
          isEnabled: 1
        },
        undoWork: {
          isEnabled: 1
        },
        problem: {
          isEnabled: 1
        },
        newIdea: {
          isEnabled: 1
        },
        selfPeakState: {
          isEnabled: 1
        },
        selfFullState: {
          isEnabled: 1
        },
        selfValueState: {
          isEnabled: 1
        },
        deductionSelf: {
          isEnabled: 1
        },
        workPlan: {
          isEnabled: 1
        },
        safeguards: {
          isEnabled: 1
        },
        needPower: {
          isEnabled: 1
        },
        isSelf: {
          isEnabled: 1
        }
      },
      workForm: {
        // 汇报对象
        reportObject: [],
        // 抄送人
        copyObject: [],
        // 今日工作成果
        workContent: '',
        // 经验分享
        experienceShare: '',
        // 未完成工作及原因
        undoWork: '',
        // 主要问题和改进措施
        problem: '',
        // 创新提案
        newIdea: '',
        //行为规范月度自评
        deductionSelf: '',
        // 需要赋能
        needPower: '',
        // 	保障措施
        safeguards: '',
        // 自我评价:巅峰状态工作
        selfPeakState: 0,
        // 自我评价:满负荷工作
        selfFullState: 0,
        // 自我评价:每时每刻都做最有价值的工作
        selfValueState: 0
      },
      messageVal: '',
      rules: {},
      savemeetinfoid: '',
      title: '写日清',
      copycharacter: '',
      score: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      makeUp: '',
      importStatu: [
        {
          text: '紧急、重要',
          query: 'urgentImport',
          val: ''
        },
        {
          text: '紧急、不重要',
          query: 'urgentNoImport',
          val: ''
        },
        {
          text: '不紧急、重要',
          query: 'noUrgentImport',
          val: ''
        },
        {
          text: '不紧急、不重要',
          query: 'noUrgentNoImport',
          val: ''
        }
      ],
      customize: {
        template: [
          {
            text: '事项',
            val: '',
            query: 'itemContent'
          },
          {
            text: '完成结果',
            val: '',
            query: 'itemResult'
          },
          {
            text: '备注',
            val: '',
            query: 'remarks'
          }
        ]
      },
      selfInputList: [],
      isGoBehaviour: false,
      isTojoy: JSON.parse(localStorage.getItem('isTojoy'))
    }
  },
  components: {
    ChoosePeople
  },
  created() {
    document.title = '幸福绩效-' + this.$route.query.name
    this.title = this.$route.query.name
    this.reportType = this.$route.query.reportType
    this.makeUp = this.$route.query.makeUp
    if (this.makeUp) {
      // let routeInfo = {
      //   'thrclear-work-day': '补日清' ,
      //   'thrclear-work-week': '补周清' ,
      //   'thrclear-work-month': '补月清' ,
      //   }
      let newTitles = {
        // [this.$route.name]: routeInfo[this.$route.name]
        [this.$route.name]: this.title
      }
      sessionStorage.setItem('exchange-title', JSON.stringify(newTitles))
    } else {
      this.$route.meta.title = this.title
      sessionStorage.setItem('exchange-title', 0)
    }
    this.reportDraftDetail()
    this.formConfig()
    this.getSubmitState()
    // this.fetchReportCheck();
    if (Number(this.reportType) === 1) {
      this.messageVal = '上次计划内容为空，同步失败'
      this.btnworkVal = '同步上次计划'
    } else if (Number(this.reportType) === 2) {
      this.messageVal = '本周日清内容为空，同步失败'
      this.btnworkVal = '同步本周日清'
    } else {
      this.messageVal = '本月周清内容为空，同步失败'
      this.btnworkVal = '同步本月周清'
    }
  },
  watch: {
    'workForm.reportObject': {
      handler: function(val, oldVal) {
        this.processPeople()
      },
      deep: true
    },
    'workForm.copyObject': {
      handler: function(val, oldVal) {
        this.processPeople()
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    // 跳转到行为规范
    toBehaviour() {
      MessageBox.confirm('即将跳转到行为规范模块，系统自动将月清内容保存为草稿。', '提示', {
        confirmButtonText: `我知道了`,
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        this.isGoBehaviour = true
        this.reportClickStage(1)
        // this.$router.push({ path: '/behaviour', query: { isDeductionSelf: this.isDeductionSelf } })
      })
    },
    //获取是否自评
    getSubmitState() {
      submitState({}).then(res => {
        // console.log(res)
        if (res.code === '000000') {
          this.isDeductionSelf = res.data
          this.workForm.deductionSelf = res.data ? '已自评' : '未自评'
          // console.log('🚀 ~ file: work-day.vue:550 ~ submitState ~ this.isDeductionSelf:', this.isDeductionSelf)
        }
      })
    },
    // 处理抄送人和汇报人，已经选过的人不能再次选择
    processPeople() {
      this.disableDataList = [...this.workForm.reportObject, ...this.workForm.copyObject]
    },
    // 查询是否可以提交三清
    async fetchReportCheck() {
      const { code, data } = await fetchReportCheck()
      if (code === '000000') {
        let datalist = data.outputList
        for (let i = 0; i < datalist.length; i++) {
          if (Number(this.reportType) === datalist[i].reportType) {
            this.subThreeClearStatu = datalist[i].isAble === 0 ? false : true
          }
        }
      }
    },
    // 配置表单
    formConfig() {
      formConfig({ type: this.$route.query.reportType }).then(res => {
        if (res && res.code === '000000') {
          let list = res.data.reportFormConfigDto
          let codeName = ''
          for (let i = 0; i < list.length; i++) {
            codeName = list[i].codeName
            this.formListState[codeName] = list[i]
          }
        } else {
          Message({
            message: '表单配置查询失败！',
            type: 'error'
          })
        }
      })
    },
    syncLastPlan() {
      let data = {
        reportType: this.$route.query.reportType
      }
      if (this.$route.query.makeUp) {
        data.repairDate = this.$route.query.makeUp
      }
      synchroplan(data).then(res => {
        if (res && res.code === '000000') {
          let data = res.data
          // let data = {
          //   "experienceShareSummary": ["111", "222"],
          //   "needPowerSummary": [],
          //   "newIdeaSummary": null,
          //   "problemSummary": ["111", "222"],
          //   "safeguardsSummary": ["111", "222"],
          //   "undoWorkSummary": ["111", "222"],
          //   "workContentSummary": ["111", "222"],
          // };
          if (
            !data ||
            (!data?.experienceShareSummary?.length &&
              !data?.needPowerSummary?.length &&
              !data?.newIdeaSummary?.length &&
              !data?.problemSummary?.length &&
              !data?.safeguardsSummary?.length &&
              !data?.undoWorkSummary?.length &&
              !data?.workContentSummary?.length)
          ) {
            Message({
              message: '暂未获取到数据，同步失败',
              type: 'error'
            })
          } else {
            if (
              this.workForm.experienceShare ||
              this.workForm.needPower ||
              this.workForm.newIdea ||
              this.workForm.problem ||
              this.workForm.safeguards ||
              this.workForm.undoWork ||
              this.workForm.workContent
            ) {
              MessageBox.confirm(`正在${this.btnworkVal}，会覆盖当前内容`, '提示', {
                confirmButtonText: `确定`,
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.processPlanData(data)
              })
            } else {
              this.processPlanData(data)
            }
          }
        }
      })
    },
    processPlanData(data) {
      for (let i in data) {
        if (!!data[i]?.length < 1) {
          continue
        } else {
          let str = ''
          for (let j = 0; j < data[i].length; j++) {
            // 	经验分享同步
            if (i === 'experienceShareSummary') {
              str += `${data[i][j]}\n`
              this.workForm.experienceShare = str
              // 	需要赋能同步
            } else if (i === 'needPowerSummary') {
              str += `${data[i][j]}\n`
              this.workForm.needPower = str
              // 创新提案同步
            } else if (i === 'newIdeaSummary') {
              str += `${data[i][j]}\n`
              this.workForm.newIdea = str
              // 	主要问题和改进措施同步
            } else if (i === 'problemSummary') {
              str += `${data[i][j]}\n`
              this.workForm.problem = str
              // 保障措施同步
            } else if (i === 'safeguardsSummary') {
              str += `${data[i][j]}\n`
              this.workForm.safeguards = str
              // 未完成工作及原因同步
            } else if (i === 'undoWorkSummary') {
              str += `${data[i][j]}\n`
              this.workForm.undoWork = str
              // 工作成果同步
            } else if (i === 'workContentSummary') {
              str += `${data[i][j]}\n`
              this.workForm.workContent = str
            }
          }
        }
      }
      Message({
        message: '同步成功！',
        type: 'success'
      })
    },
    reportDraftDetail() {
      let data = {
        reportType: this.reportType
      }
      reportDraftDetail(data).then(res => {
        if (res && res.code === '000000') {
          if (!res.data) {
            return false
          }
          let data = res.data
          this.savemeetinfoid = data.id
          // 汇报对象*
          this.workForm.reportObject = data.reportObject ? data.reportObject : []
          // 抄送人
          this.workForm.copyObject = data.copyObject ? data.copyObject : []
          // 	工作成果
          this.workForm.workContent = data.workContent
          // 经验分享
          this.workForm.experienceShare = data.experienceShare
          // 未完成工作及原因
          this.workForm.undoWork = data.undoWork
          // 主要问题和改进措施
          this.workForm.problem = data.problem
          // 创新提案
          this.workForm.newIdea = data.newIdea
          // 自我评价:巅峰状态工作
          this.workForm.selfPeakState = data.selfPeakState
          // 满负荷工作
          this.workForm.selfFullState = data.selfFullState
          // 需要赋能
          this.workForm.needPower = data.needPower
          // 保障措施
          this.workForm.safeguards = data.safeguards
          // 每刻的工作都最有价值
          this.workForm.selfValueState = data.selfValueState
          for (let i = 0; i < this.importStatu.length; i++) {
            for (let item in data.workPlan) {
              if (this.importStatu[i].query == item) {
                this.importStatu[i].val = data.workPlan[item]
                continue
              }
            }
          }
          this.selfInputList = []
          if (data.selfOutputList) {
            for (let j = 0; j < data.selfOutputList.length; j++) {
              let obj = {
                template: [
                  {
                    query: 'itemContent',
                    text: '事项',
                    val: data.selfOutputList[j].itemContent
                  },
                  {
                    query: 'itemResult',
                    text: '完成结果',
                    val: data.selfOutputList[j].itemResult
                  },
                  {
                    query: 'remarks',
                    text: '备注',
                    val: data.selfOutputList[j].remarks
                  }
                ]
              }
              this.selfInputList.push(obj)
            }
          }
        }
      })
    },
    async reportClickStage(val) {
      // console.log('val', val, this.$route.query)
      if (val === 1) {
        let data = await this.getQueryData(val)
        // console.log('data', data)
        var re = /[\u4e00-\u9fa5]|[a-zA-Z]/
        // console.log(re.test(data.newIdea))
        if (!re.test(data.newIdea)) {
          Message({
            message: `${this.formListState.newIdea.showName}必需要包含文本`,
            type: 'error'
          })
          return
        }
        let arr = []
        for (let i in data) {
          if (i === 'reportType' || i === 'reportStage') {
            continue
          } else if (i === 'workPlan') {
            if (data[i].noUrgentImport === '' && data[i].noUrgentNoImport === '' && data[i].urgentImport === '' && data[i].urgentNoImport === '') {
              arr.push('0')
            } else {
              arr.push('1')
            }
          } else if (data[i] === [] || data[i] === '' || data[i].length < 1) {
            arr.push('0')
          } else {
            arr.push('1')
          }
        }
        if (arr.includes('1')) {
          save(this.getQueryData(val)).then(res => {
            if (res && res.code === '000000') {
              Message({
                message: '草稿保存成功！',
                type: 'success'
              })
              this.reportDraftDetail()
            }
          })
          this.isGoBehaviour && this.$router.push({ path: '/behaviour', query: { isDeductionSelf: this.isDeductionSelf } })
          return
        } else {
          Message({
            message: '请至少填写一项',
            type: 'error'
          })
          return
        }
      }
      this.$refs['workForm'].validate(valid => {
        // if(this.subThreeClearStatu === false) {
        //   Message({
        //       message: '今日无需提交三清！',
        //       type: 'error'
        //     })
        //     return;
        // }
        if (valid) {
          let workPlan = {}
          for (let i = 0; i < this.importStatu.length; i++) {
            workPlan[this.importStatu[i].query] = this.importStatu[i].val
          }
          if (this.formListState.selfPeakState.isEnabled === 1 && this.formListState.selfPeakState.isRequired === 1 && (this.workForm.selfPeakState === 0 || this.workForm.selfPeakState < 10)) {
            Message({
              message: '请选择' + this.formListState.selfPeakState.showName,
              type: 'error'
            })
            return false
          }
          if (this.formListState.selfFullState.isEnabled === 1 && this.formListState.selfFullState.isRequired === 1 && (this.workForm.selfFullState === 0 || this.workForm.selfFullState < 10)) {
            Message({
              message: '请选择' + this.formListState.selfFullState.showName,
              type: 'error'
            })
            return false
          }
          if (this.formListState.selfValueState.isEnabled === 1 && this.formListState.selfValueState.isRequired === 1 && (this.workForm.selfValueState === 0 || this.workForm.selfValueState < 10)) {
            Message({
              message: '请选择' + this.formListState.selfValueState.showName,
              type: 'error'
            })
            return false
          }
          // 如果是写月清 和行为规范有codename 和 isRequired  必填
          // 如果codename =  deductionSelf 和必填就校验
          if (this.$route.query.reportType == '3' && !this.isDeductionSelf && this.formListState?.deductionSelf.codeName === 'deductionSelf' && this.formListState?.deductionSelf.isRequired) {
            Message({
              message: this.formListState.deductionSelf.showName + '未填写',
              type: 'error'
            })
            return false
          }
          if (
            this.formListState.workPlan.isEnabled === 1 &&
            this.formListState.workPlan.isRequired === 1 &&
            !workPlan.noUrgentImport &&
            !workPlan.noUrgentNoImport &&
            !workPlan.urgentImport &&
            !workPlan.urgentNoImport
          ) {
            Message({
              message: '请至少填写一项' + this.nextWorkeVal + '工作计划',
              type: 'error'
            })
            return false
          }
          if (val == 2) {
            this.subBtnStatu = !this.subBtnStatu
          } else {
            this.saveBtnStatu = !this.saveBtnStatu
          }
          // 判断是补交还是提交
          if (this.makeUp) {
            repairSave(this.getQueryData(val)).then(res => {
              if (res && res.code === '000000') {
                Message({
                  message: '补交成功！',
                  type: 'success'
                })
                this.$router.push({ name: 'thrclear-mine' })
              } else {
                this.saveBtnStatu = false
                this.subBtnStatu = false
              }
            })
          } else {
            save(this.getQueryData(val)).then(result => {
              if (result && result.code === '000000') {
                if (val == 1) {
                  this.saveBtnStatu = !this.saveBtnStatu
                  Message({
                    message: '草稿保存成功！',
                    type: 'success'
                  })
                  this.$router.push({ name: 'thrclear-mine' })
                } else {
                  this.subBtnStatu = !this.subBtnStatu
                  Message({
                    message: '提交成功！',
                    type: 'success'
                  })
                  this.$router.push({ name: 'thrclear-mine' })
                }
              } else if (result && result.code === '130202') {
                this.saveBtnStatu = false
                this.subBtnStatu = false
                let text = ''
                if (Number(this.reportType) === 1) {
                  text = '日清'
                } else if (Number(this.reportType) === 2) {
                  text = '周清'
                } else {
                  text = '月清'
                }
                MessageBox.confirm(`未到${text}提交时间，是否保存为草稿？`, '提示', {
                  confirmButtonText: `确定`,
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.reportClickStage(1)
                })
              } else {
                Message({
                  message: result.msg,
                  type: 'warning'
                })
                this.saveBtnStatu = false
                this.subBtnStatu = false
              }
            })
          }
        } else {
          return false
        }
      })
    },
    // 获取调用保存/提交草稿 周/日/月清接口的入参
    getQueryData(val) {
      // console.log('//', this.isDeductionSelf)
      let data = {
        // 报告类型 1 日报 2 周报 3 月报
        reportType: this.reportType,
        //  三清阶段 1 草稿；2 提交
        reportStage: val,
        //行为规范月度自评
        actionMainId: this.isDeductionSelf,
        // 汇报对象
        reportObject: this.formListState.reportObject.isEnabled === 1 ? this.workForm.reportObject : [],
        // 抄送人
        copyObject: this.formListState.copyObject.isEnabled === 1 ? this.workForm.copyObject : [],
        // 今日工作成果
        workContent: this.formListState.workContent.isEnabled === 1 ? this.workForm.workContent : '',
        // 经验分享
        experienceShare: this.formListState.experienceShare.isEnabled === 1 ? this.workForm.experienceShare : '',
        // 未完成工作及原因
        undoWork: this.formListState.undoWork.isEnabled === 1 ? this.workForm.undoWork : '',
        // 主要问题和改进措施
        problem: this.formListState.problem.isEnabled === 1 ? this.workForm.problem : '',
        // 创新提案
        newIdea: this.formListState.newIdea.isEnabled === 1 ? this.workForm.newIdea : '',
        // 自我评价:巅峰状态工作
        selfPeakState: this.formListState.selfPeakState.isEnabled === 1 ? (this.workForm.selfPeakState === 0 ? '' : this.workForm.selfPeakState) : '',
        // 自我评价:满负荷工作
        selfFullState: this.formListState.selfFullState.isEnabled === 1 ? (this.workForm.selfFullState === 0 ? '' : this.workForm.selfFullState) : '',
        // 每刻的工作都最有价值
        selfValueState: this.formListState.selfValueState.isEnabled === 1 ? (this.workForm.selfValueState === 0 ? '' : this.workForm.selfValueState) : '',
        // 需要赋能
        needPower: this.formListState.needPower.isEnabled === 1 ? this.workForm.needPower : '',
        // 保障措施
        safeguards: this.formListState.safeguards.isEnabled === 1 ? this.workForm.safeguards : ''
      }
      // 	补交日期 只在补交流程传参
      if (this.makeUp) {
        data.repairDate = this.makeUp
      }
      // 紧急重要/紧急不重要/不紧急重要/不紧急不重要
      let workPlan = {}
      for (let i = 0; i < this.importStatu.length; i++) {
        workPlan[this.importStatu[i].query] = this.importStatu[i].val
      }
      data.workPlan = this.formListState.workPlan.isEnabled === 1 ? workPlan : {}

      let list = []
      for (let i = 0; i < this.selfInputList.length; i++) {
        let obj = {}
        for (let j = 0; j < this.selfInputList[i].template.length; j++) {
          obj[this.selfInputList[i].template[j].query] = this.selfInputList[i].template[j].val
        }
        list.push(obj)
      }
      data.selfInputList = JSON.parse(JSON.stringify(list))
      if (this.savemeetinfoid !== '') {
        data.id = this.savemeetinfoid
      }
      for (let i in data) {
        if (i === 'workPlan') {
          for (let j in data[i]) {
            if (data[i][j] === '' || data[i][j] === null) {
              delete data[i][j]
            }
          }
          if (!Object.keys(data[i]).length) {
            delete data[i]
          }
          continue
        }
        if (i === 'selfInputList') {
          const _dataMeng = JSON.parse(JSON.stringify(data.selfInputList))
          let _dataMengArr = []
          for (let x = 0; x < _dataMeng.length; x++) {
            const _item = _dataMeng[x]
            const _itemObj = {}
            for (let k in _item) {
              if (_item[k]) {
                _itemObj[k] = _item[k]
              }
            }
            if (Object.keys(_itemObj).length) {
              _dataMengArr.push(_itemObj)
            }
          }

          data.selfInputList = this.formListState.isSelf.isEnabled === 1 ? _dataMengArr : []
        }
        if (data[i] === undefined || data[i] === 'undefined' || data[i] === null || data[i] === '' || data[i] === [] || data[i].length < 1) {
          delete data[i]
        }
      }
      return data
    },
    deleteSelfList(index) {
      MessageBox.confirm('确定删除当前自定义事项吗', '提示', {
        confirmButtonText: `确定`,
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selfInputList.splice(index, 1)
        })
        .catch(() => {
          Message.info(`已取消操作`)
        })
    },
    addSelfInputList() {
      if (this.selfInputList.length > 14) {
        Message({
          message: '最多十五组自定义项',
          type: 'error'
        })
      } else {
        this.selfInputList.push(JSON.parse(JSON.stringify(this.customize)))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.#{$css-prefix}-write-dayContent {
  width: 100%;
  height: 100%;
  .threeDown {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
  }
  .resTitle {
    display: none;
    width: 100%;
    height: 60px;
    background: $white;
    opacity: 0.99;
    line-height: 60px;
    padding-left: 30px;
    // margin-bottom: 20px;
  }
  .box {
    height: 100%;
    padding: 10px 30px 20px 30px;
    overflow: auto;
    flex: 1;
    .person-selected {
      /deep/.el-select__tags .el-tag {
        margin: 0 10px 0 0;
        line-height: 28px;
        padding: 0 17px 0 12px;
      }
    }
    /deep/.is-active {
      .el-radio-button__inner {
        color: #3d7eff;
      }
    }
    /deep/.el-radio-button__inner {
      background-color: white;
    }
    /deep/.el-select__input {
      margin-left: 0 !important;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      min-width: 30px;
    }
    /deep/.el-input__inner {
      border: none !important;
      padding-left: 0;
    }
    .bottomNone {
      margin-top: 0;
    }
    .delete {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      .el-icon-delete {
        cursor: pointer;
      }
      .el-icon-delete:hover {
        color: $blue;
      }
    }
    .form-list {
      position: relative;
      .item:last-child {
        box-shadow: none;
      }
      .nextplan {
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: $darkgrey;
        line-height: 12px;
        position: absolute;
        right: 10px;
        top: -16px;
        cursor: pointer;
        i {
          font-size: 14px;
          padding-right: 5px;
        }
      }
      .nextplan:hover {
        color: $blue;
      }
      .conduct {
        line-height: 35px;
        font-size: 15px;
        /deep/ .el-input__inner {
          cursor: pointer;
        }
      }
      .red {
        /deep/ .el-input__inner {
          color: #ff546c;
        }
      }
    }
    .resDiv {
      background: white;
    }
    .scoreTitle {
      margin: 15px 0;
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: $lightblack;
      line-height: 14px;
      display: inline-block;
    }
    .scoreSpe {
      display: inline-block;
    }
    .star {
      color: $red;
    }
    .scoreNum {
      width: 80px;
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 12px;
      margin-left: 7px;
    }
    // padding-bottom: 18px;
    .telldivList:last-child {
      box-shadow: none;
    }
    .ownPline:last-child {
      box-shadow: none;
    }
    .el-form-item__label {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .el-form-item__content {
      box-shadow: 0px 1px 0px 0px #eeeeee;
    }
    .ownPline {
      height: 64px;
      background: rgba(255, 255, 255, 0);
      box-shadow: 0px 1px 0px 0px $linecolor;
      line-height: 64px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 15px;
      .topStatu {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
    .telldivList {
      background: rgba(255, 255, 255, 0);
      // box-shadow: 0px 1px 0px 0px $linecolor;
      padding-top: 12px;
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
      &:not(:last-child):focus-within {
        border-color: $blue;
      }
    }
    .pleaseWriteTell {
      font-size: 15px;
      color: #666666;
    }
    .tell {
      font-size: 12px;
      color: #333333;
    }
    .addPlan {
      height: 64px;
      background: $white;
      border-radius: 5px;
      line-height: 64px;
      text-align: center;
      margin-top: 10px;
      cursor: pointer;
      .el-icon-circle-plus-outline {
        padding-right: 10px;
        font-size: 15px;
      }
    }
    .addPlan:hover {
      color: $blue;
    }
  }
  .saveBox {
    height: 64px;
    background: $white;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    line-height: 64px;
    text-align: right;
    padding-right: 30px;
    z-index: 2;
    .subButton {
      width: 167px;
      height: 44px;
    }
  }
  .el-radio-button--medium .el-radio-button__inner {
    padding: 11px 24px;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: white !important;
    color: #3d7eff;
    border-color: #3d7eff;
    box-shadow: -1px 0 0 0 #3d7eff;
  }
}
</style>
