export const sumMixin = {
  data() {
    return {
      count: 0,
      loading: false,
      code: 99, //强制提交9  目标考评完成确认提交99
      orgList: [],
      userList: [],
      isSort: true,
      levels: [],
      SumS: 0,
      SumA: 0,
      Srange: 0, //S可用数量
      Arange: 0, //A可用数量
      showDialog: false,
      showPassDialog: false,
      resultList: [],
      orglen: 10, //参评组织人数
      orgObj: {
        name: '网科天九共享网络科技集团有限公司',
        date: '2020年10月'
      },
      max: 0,
      levelList: [
        {
          level: 'S',
          ratio: 0,
          big: 0,
          small: 1
        },
        {
          level: 'A',
          ratio: 0,
          big: 0,
          small: 1
        },
        {
          level: 'B',
          ratio: 0,
          big: 0,
          small: 0
        },
        {
          level: 'C',
          ratio: 0.6,
          big: 0,
          small: 1
        }
      ],
      levelData: {
        S: 0,
        A: 0,
        C: 0
      },
      benchmark: 0, //配置规则  0 无限制 [1,2=平均分] [3,4=基准分]
      benchmarkScore: 0, //基准分
      averageScore: 0 //平均分
    }
  },
  props: {
    date: {
      type: [String, Number],
      default: null
    },
    orgId: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    cfgId: {
      type: [Number, String],
      default: null
    },
    id: {
      type: [Number, String],
      default: null
    }
  },
  filters: {
    formateVal(val, code) {
      if (!val) {
        return ''
      }
      return code === 1 ? String(val).slice(-2) : String(val).slice(0, 4)
    }
  },
  methods: {
    //计算平均分
    handleAverageScore(benchmark, benchmarkScore, data = []) {
      let averageScore = 0
      if (benchmark) {
        if ([3, 4].includes(this.benchmark)) {
          averageScore = benchmarkScore
        } else {
          //摘出targetState=99(目标考评完成)的人
          let averageData = data.filter(el => el.targetState === 99)
          let sumList = averageData.map(el => el.targetReal)
          //求平均分
          averageScore =
            this.$scoreSum(...sumList) && averageData.length
              ? parseFloat(this.$scoreSum(...sumList) / averageData.length).toFixed(2)
              : 0
        }
      }
      return averageScore
    },
    handleTopDataFn(S, A) {
      let S1 = this.SumS - S
      let A1 = this.SumA - A
      this.levelList[0].small = parseFloat(S1).toFixed(2)
      this.levelList[1].small = parseFloat(A1).toFixed(2)
      this.levelList[0].big = S
      this.levelList[1].big = A
      let B =
        Number(this.levelList[0].big) +
        Number(this.levelList[1].big) +
        Number(this.levelList[3].big)
      this.levelList[2].big = Number(this.count) - B
    },
    handleSum(levels, targetMaxScore) {
      this.max = targetMaxScore
      // this.levelList = levels
      this.levelList.forEach(item => {
        let el = levels.find(el => el.level === item.level)
        this.$set(item, 'remainder', el.remainder)
        this.$set(item, 'ratio', el.ratio)
      })
      this.levelList.forEach(el => {
        /**
         * big等级个数--SA 组织数*比例 C组织数*比例+结余  B=组织数-S-A-B
         * small余数 --SA 上月结余  C （组织数*比例+结余）取整再取余
         */

        let sum = parseFloat(this.count * el.ratio).toFixed(2)
        let middle =
          sum && Math.floor(sum)
            ? parseFloat(sum % Math.floor(sum)).toFixed(2)
            : !sum
            ? 0
            : parseFloat(sum)
        let small = el.remainder + parseFloat(middle)
        if (el.level === 'C') {
          sum = parseFloat(this.count * el.ratio + Number(el.remainder)).toFixed(2)
          small =
            sum && Math.floor(sum)
              ? parseFloat(parseFloat(sum % Math.floor(sum)).toFixed(2))
              : !sum
              ? 0
              : parseFloat(sum)
        }
        let big = Math.floor(sum)
        this.$set(el, 'big', big)
        this.$set(el, 'small', parseFloat(small.toFixed(2)))
        if (el.level !== 'B') {
          this.levelData[el.level] = el.remainder || 0
        }
      })
      //B=count-S-A-C整数部分
      let sum =
        this.count -
        (Number(this.levelList[0].big) +
          Number(this.levelList[1].big) +
          Number(this.levelList[3].big))
      let rs = this.levelList.find(el => el.level === 'B')
      this.$set(rs, 'big', sum)
      this.$set(this.levelList, 2, rs)
      this.Srange = Math.floor(this.levelList[0].big + this.levelList[0].small)
      this.Arange = Math.floor(this.levelList[1].big + this.levelList[1].small)
      this.SumS = this.count * this.levelList[0].ratio + +this.levelList[0].remainder
      this.SumA = this.count * this.levelList[1].ratio + +this.levelList[1].remainder
    },
    handleFlag(flag) {
      //是否排序过
      this.isSort = flag
    },
    /**
     * 排序回调
     */
    createComprisonFunction(propertyName) {
      return function(object1, object2) {
        var value1 = object1[propertyName]
        var value2 = object2[propertyName]
        return value2 - value1
      }
    },

    /**
     * 生成等级数组
     */
    handleLevels(num = 0) {
      let arrS = this.handleLevel('S', this.levelList[0].big)
      let arrA = this.handleLevel('A', this.levelList[1].big)
      let arrB = this.handleLevel('B', num)
      let arrC = this.handleLevel('C', this.levelList[3].big)
      let rs = [...arrS, ...arrA, ...arrB, ...arrC]
      return rs
    },
    /**
     * 生成SABC
     */
    handleLevel(code, count) {
      if (count) {
        let arr = []
        for (let i = 0; i < count; i++) {
          arr.push(code)
        }
        return arr
      } else {
        return []
      }
    }
  }
}
