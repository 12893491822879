export default [
  {
    path: '/customer',
    name: 'customer',
    redirect: '/customer/channel',
    component: () => import(/* webpackChunkName: "index" */ '@/views/team/index.vue'),
    meta: {
      title: '好人好事'
    },
    children: [
      {
        path: 'channel',
        name: 'customer-channel',
        component: () => import(/* webpackChunkName: "customer-channel" */ '@/views/team/channel'),
        meta: {
          id: '1',
          title: '好人好事',
          module: 'customer',
          pid: '0'
        },
        props: route => ({
          code: route.query.code,
          name: route.query.name
        })
      },
      {
        path: 'initiate',
        component: () => import('@/views/team/initiate'),
        name: 'customer-initiate',
        meta: {
          id: '1_1',
          title: '我发起的',
          module: 'customer',
          pid: '1'
        },
        props: route => ({ moduleName: route.query.moduleName || 'all' })
      },
      {
        path: 'initiateDetail',
        component: () => import('@/views/team/customDetail'),
        name: 'customer-initiateDetail',
        meta: {
          id: '1_1_1',
          title: '好人好事提案审批',
          module: 'customer',
          pid: '1_1'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode,
          moduleName: route.query.moduleName || 'all'
        })
      },
      {
        path: 'initiateProposal',
        component: () => import('@/views/team/addProposalCustomer'),
        name: 'customer-initiateProposal',
        meta: {
          id: '1_2',
          title: '发起提案',
          module: 'customer',
          pid: '1'
        },
        props: route => ({
          type: route.query.type,
          id: route.query.id,
          moduleName: route.query.moduleName || 'all'
        })
      },
      {
        path: 'approval',
        component: () => import('@/views/team/approval'),
        name: 'customer-approval',
        meta: {
          id: '1_3',
          title: '我审批的',
          module: 'customer',
          pid: '1'
        },
        props: route => ({ moduleName: route.query.moduleName || 'approval' })
      },
      {
        path: 'approvalDetail',
        component: () => import('@/views/team/customDetail'),
        name: 'customer-approvalDetail',
        meta: {
          id: '1_3_1',
          title: '好人好事提案审批',
          module: 'customer',
          pid: '1_3'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode,
          moduleName: route.query.moduleName || 'approval'
        })
      },
      {
        path: 'all',
        component: () => import('@/views/team/customDetail'),
        name: 'customer-all',
        meta: {
          id: '1_4',
          title: '好人好事详情',
          module: 'customer',
          pid: '1'
        },
        props: route => ({
          id: route.query.id,
          type: route.query.type,
          modelCode: route.query.modelCode
        })
      }
    ]
  }
]
