const moduleTeam = {
  state: () => ({
    teamObj: {}
  }),

  mutations: {
    setTeamObj(state, data) {
      state.teamObj = data
    }
  },

  actions: {},

  getters: {
    teamObj(state) {
      return state.teamObj
    }
  }
}

export default moduleTeam
