export default [
  {
    path: '/satisfaction',
    name: 'satisfaction',
    redirect: '/satisfaction/mine',
    component: () =>
      import(/* webpackChunkName: "satisfaction" */ '@/views/satisfaction/index.vue'),
    children: [
      {
        path: '',
        redirect: 'mine'
      },
      {
        path: 'mine',
        name: 'satisfaction-mine',
        component: () =>
          import(/* webpackChunkName: "satisfaction-mine" */ '@/views/satisfaction/mine.vue'),
        meta: {
          id: '1',
          title: '客户满意度',
          module: 'satisfaction',
          pid: '0'
        },
        /**
         * 收到的评价：received
         * 待我评价：comment
         * 我已评的：complete
         */
        props: route => ({ moduleName: route.query.moduleName || 'received' })
      },
      {
        path: 'underlings',
        name: 'satisfaction-underlings',
        component: () =>
          import(
            /* webpackChunkName: "satisfaction-underlings" */ '@/views/satisfaction/underlings.vue'
          ),
        meta: {
          id: '2',
          title: '客户满意度',
          module: 'satisfaction',
          pid: '0'
        },
        /**
         * 团队满意度：underlings
         * 待我审批：approve
         * 投诉处理：complaint
         * 感动处理：touch
         */
        props: route => ({ moduleName: route.query.moduleName || 'underlings' })
      },
      {
        path: 'inviter',
        name: 'satisfaction-inviter',
        component: () =>
          import(/* webpackChunkName: "satisfaction-inviter" */ '@/views/satisfaction/inviter.vue'),
        meta: {
          id: '1_1',
          title: '我的评价人',
          module: 'satisfaction',
          pid: '1'
        },
        props: route => ({ inviterPersonNums: route.query.inviterPersonNums })
      }
    ]
  }
]
