export const democracyNav = {
  id: '1',
  label: '民主互评',
  name: 'democracy',
  path: '/democracy',
  children: [
    {
      id: '1_1',
      label: '我的互评',
      name: 'democracy-mine',
      path: '/democracy/mine?moduleName=received',
      icon: 'iconminzhuhuping'
    },
    {
      id: '1_2',
      label: '团队互评',
      name: 'democracy-underlings',
      path: '/democracy/underlings?moduleName=myTeam',
      icon: 'iconevaluation',
      badge: 'false' //默认红点
    }
  ]
}
export const organizationNav = {
  id: '1',
  label: '组织绩效',
  name: 'organizational',
  path: '/organizational',
  children: [
    {
      id: '1_1',
      label: '得分汇总',
      name: 'scoreSum',
      path: '/organizational/scoreSum',
      icon: 'iconminzhuhuping'
    },
    {
      id: '1_2',
      label: '绩效考评',
      name: 'appraisal',
      path: '/organizational/appraisal',
      icon: 'iconevaluation'
    }
  ]
}

export const satisfactionNav = {
  id: '1',
  label: '客户满意度',
  name: 'satisfaction',
  path: '/satisfaction',
  children: [
    {
      id: '1_1',
      label: '我的满意度',
      name: 'satisfaction-mine',
      path: '/satisfaction/mine?moduleName=received',
      icon: 'iconminzhuhuping',
      num: 0
    },
    {
      id: '1_2',
      label: '下属满意度',
      name: 'satisfaction-underlings',
      path: '/satisfaction/underlings?moduleName=underlings',
      icon: 'iconevaluation',
      num: 0
    }
  ]
}

export const targetNav = {
  id: '1',
  label: '工作目标',
  name: 'mytarget',
  path: '/mytarget',
  children: [
    {
      id: '1_1',
      label: '我的目标',
      name: 'mytarget-mine',
      path: '/mytarget',
      icon: 'icontarget01',
      num: 0
    },
    {
      id: '1-2',
      label: '目标考评',
      name: 'mytarget-evaluation',
      path: '/mytarget/evaluation',
      icon: 'icontarget03',
      num: 0
    },
    {
      id: '1-3',
      label: '年度目标',
      name: 'annual-target',
      path: '/mytarget/annual',
      icon: 'icontarget04'
    }
  ]
}
