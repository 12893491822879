<template>
  <div>
    <div class="level-grade">
      <slot name="grade">
        {{ item.grade || 0 }}
      </slot>
    </div>
    <!-- {{ $route.query.type }} -->
    <!-- 如果是isTojoy 天九企业   个人绩效考评时 $route.query.type == 2 -->
    <div class="level-bottom">
      <span class="rank" :class="'rank-' + item.level" v-if="item.level != 'c'">{{ item.level == 'C' && isTojoy && $route.query.type == 2 ? item.level + '+D' : item.level || '' }}</span>
      <slot name="rank">
        <span class="text">{{ item.text || '' }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'level-model',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isTojoy: JSON.parse(localStorage.getItem('isTojoy'))
    }
  }
}
</script>

<style lang="scss" scoped>
.level-grade {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: $lightblack;
  line-height: 21px;
  margin-bottom: 5px;
}
.level-bottom {
  font-size: 12px;
  line-height: 12px;
  .rank {
    font-weight: 600;
    color: $darkgrey;
  }
  .rank-C,
  .rank-D {
    color: $red;
  }
  .rank-S {
    color: $C89557;
  }
  .rank-A {
    color: $blue;
  }
}
</style>
