export default [
  {
    path: '/thrclear',
    name: 'thrclear',
    redirect: '/thrclear/mine',
    component: () => import(/* webpackChunkName: "democracy" */ '@/views/thrclear/index.vue'),
    meta: {
      title: '三清两会'
    },
    children: [
      {
        path: '',
        redirect: 'mine'
      },
      {
        path: 'mine',
        name: 'thrclear-mine',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/mine.vue'),
        meta: {
          id: '1',
          title: '三清两会',
          module: 'thrclear',
          pid: '0'
        }
      },
      {
        path: 'holdmeeting',
        name: 'thrclear-hold-meeting',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/holdmeeting.vue'),
        meta: {
          id: '2_1',
          title: '开会',
          module: 'thrclear',
          pid: '2'
        }
      },
      {
        path: 'meeting',
        name: 'thrclear-meeting',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/meeting.vue'),
        meta: {
          id: '2',
          title: '两会记录',
          module: 'thrclear',
          pid: '0'
        }
      },
      {
        path: 'writeday',
        name: 'thrclear-work-day',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/work-day.vue'),
        meta: {
          id: '1_1',
          title: '写日清',
          module: 'thrclear',
          pid: '1'
        }
      },
      {
        path: 'writeweek',
        name: 'thrclear-work-week',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/work-day.vue'),
        meta: {
          id: '1_2',
          title: '写周清',
          module: 'thrclear',
          pid: '1'
        }
      },
      {
        path: 'writemonth',
        name: 'thrclear-work-month',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/work-day.vue'),
        meta: {
          id: '1_3',
          title: '写月清',
          module: 'thrclear',
          pid: '1'
        }
      },
      {
        path: 'conferenceres',
        name: 'thrclear-conference-res',
        component: () =>
          import(/* webpackChunkName: "democracy-mine" */ '@/views/thrclear/conference-res.vue'),
        meta: {
          id: '2_2',
          title: '会议决议',
          module: 'thrclear',
          pid: '2'
        }
      }
    ]
  }
]
