export default [
  {
    path: '/behaviour',
    name: 'behaviour',
    redirect: '/behaviour/query',
    component: () => import(/* webpackChunkName: "behaviour" */ '@/views/behaviour/index.vue'),
    children: [
      {
        path: '',
        redirect: 'query'
      },
      {
        path: 'query',
        name: 'behaviour-query',
        component: () =>
          import(/* webpackChunkName: "behaviour-query" */ '@/views/behaviour/query.vue'),
        meta: {
          id: '1',
          title: '行为规范',
          module: 'behaviour',
          pid: '0'
        }
      }
    ]
  }
]
