import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import state from './state'
import mutations from './mutations'
import moduleSatisfaction from './module/satisfaction'
import moduleBDemocracy from './module/democracy'
import moduleBreadcrumb from './module/breadcrumb'
import modulePersoncenter from './module/personcenter'
import moduleTeam from './module/team'
Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  state,
  mutations,
  modules: {
    satisfaction: {
      namespaced: true,
      ...moduleSatisfaction
    },
    breadcrumb: {
      namespaced: true,
      ...moduleBreadcrumb
    },
    democracy: {
      namespaced: true,
      ...moduleBDemocracy
    },
    team: {
      namespaced: true,
      ...moduleTeam
    },
    personcenter: {
      namespaced: true,
      ...modulePersoncenter
    }
  }
})
