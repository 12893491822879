/**
 * 获取url参数
 * @param key 可选
 * @param url 可选
 */
export const getUrlParams = (key, url) => {
  url = url || window.location.href
  const params = {}
  url.replace(/([^?&]+)=([^?&]+)/g, (s, v, k) => {
    params[decodeURIComponent(v)] = decodeURIComponent(k)
    return k + '=' + v
  })
  return (key ? params[key] : params) || ''
}

/**
 * 空判断
 * @param args
 * @return {boolean}
 */
export const isEmpty = args => args === null || args === undefined || args === ''

/**
 * 空对象验证
 * @param obj
 * @returns {boolean}
 */
export const isEmptyObject = obj => {
  if (!obj) return true
  let t
  for (t in obj) return false
  return true
}
/**
 * 获取时间戳
 * @returns {number}
 */
export const getTimeStamp = () => {
  return new Date().getTime()
}

/**
 * 获取临时token
 */
export const getTemToken = () => {
  return 'L' + getTimeStamp() + getRandomString()
}

/**
 * 获取6位随机数
 * @returns {number}
 */
export const getRandomString = () => {
  let mm = Math.random()
  let six = null
  if (mm > 0.1) {
    six = Math.round(mm * 1000000)
  } else {
    mm += 0.1
    six = Math.round(mm * 1000000)
  }
  return six
}
/**
 * 去除左右空格
 */
export const trim = str => {
  if (!str || typeof str !== 'string') return str
  return str.replace(/^\s+|\s+$/g, '')
}

/**
 * 判断是否数字
 */
export const isNumber = args => /^[0-9]*$/.test(trim(args))

//过滤表情
export const checkEmojiRule = value => {
  const emojiRule = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
  return emojiRule.test(value)
}

/**
 * 对象合并
 */
export const extend = (source, target, exclude = []) => {
  for (const prop in source) {
    if (Object.prototype.hasOwnProperty.call(source, prop) && Object.prototype.hasOwnProperty.call(target, prop) && !exclude.includes(prop)) {
      source[prop] = target[prop]
    }
  }
  return source
}

export const checkMobile = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入手机号'))
  } else {
    const reg = /^1\d{10}$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('手机号格式错误'))
    }
  }
}

/**
 * 对象深拷贝
 */
export const deepCopy = obj => {
  if (obj === null) return null
  let result
  // 判断是否是简单数据类型
  if (typeof obj === 'object') {
    // 复杂数据类型
    result = obj.constructor === Array ? [] : {}
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, 'key')) {
        result[key] = typeof obj[key] === 'object' ? deepCopy(obj[key]) : obj[key]
      }
    }
  } else {
    // 简单数据类型,直接赋值
    result = obj
  }
  return result
}

/**
 * 校验是否有3位及以上连续字符 如123 abc
 * @param value
 * @returns {boolean}
 */
export const lxStr = value => {
  var arr = value.split('')
  var flag = false
  for (var i = 1; i < arr.length - 1; i++) {
    var firstIndex = arr[i - 1].charCodeAt()
    var secondIndex = arr[i].charCodeAt()
    var thirdIndex = arr[i + 1].charCodeAt()
    thirdIndex - secondIndex == 1
    secondIndex - firstIndex == 1
    if (thirdIndex - secondIndex == 1 && secondIndex - firstIndex == 1) {
      flag = true
    }
  }
  return flag
}

/**
 * 密码长度须在6~16位数字+字母+特殊字符
 * @type {RegExp}
 */
// export const passwdReg = /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[\W]).{6,16}$/
/**
 * 6-16个字符，包含字母、数字、标点任意2种组合
 * @type {RegExp}
 */
export const passwdReg = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/
/**
 * 连续字符
 */
export const repeatReg = /([A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])\1{2,}/
/**
 *过滤空值，并求和
 */
export const scoreSum = (...org) => {
  return org
    .filter(el => typeof el !== 'object')
    .reduce((c, p) => {
      return c + p
    }, 0)
}
/**
 * 保留2位小数
 */
export const formateNumber = val => {
  return parseFloat(parseFloat(val).toFixed(2))
}
/**
 *过滤接口参数前后空格
 * @type {object}
 */
export const filterParamsTrim = obj => {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].replace(/(^\s*)|(\s*$)/g, '')
    } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      filterParamsTrim(obj[key])
    } else if (typeof obj[key] === 'object' && Array.isArray(obj[key])) {
      for (let item of obj[key]) {
        if (typeof item === 'object') {
          filterParamsTrim(item)
        }
      }
    }
  }
  return obj
}
/**
 * 过滤单个字段前后空格
 * @type {string}
 */
export const filterTrim = val => {
  return String(val).replace(/(^\s*)|(\s*$)/g, '')
}
/**
 * 表单校验空格自定义方法
 */
export const checkTrim = (rule, value, callback) => {
  if (!value.trim().length) {
    return callback(new Error(''))
  } else {
    return callback()
  }
}
/**
 * 获取文件名，不带后缀
 */
export const splitFileName = url => {
  //获取最后一个/的位置
  let site = url.lastIndexOf('.')
  //截取最后一个.前的值
  return url.substring(0, site)
}
/**
 * 获取文件后缀
 */
export const getFileSuffix = url => {
  return url.replace(/.+\./, '').toLowerCase()
}

/**
 * 获取当前日期在这个月第几周
 */
export const getMonthWeek = (a, b, c) => {
  const date = new Date(a, parseInt(b) - 1, c)
  const w = date.getDay()
  const d = date.getDate()
  return Math.ceil((d + 6 - w) / 7)
}

// 获取当前日期在这个月上中下旬
export const getPerWeek = day => {
  if (Math.ceil(day / 10) == 1) {
    return '上旬'
  } else if (Math.ceil(day / 10) == 2) {
    return '中旬'
  } else if (Math.ceil(day / 10) == 3) {
    return '下旬'
  }
}
/**
 *
 * 时间处理，当月显示日，跨月显示月日，跨年显示年月日
 */
export const getStrideDate = day => {
  let myDate = new Date()
  let newMonth = myDate.getMonth() + 1
  let newYear = myDate.getFullYear()
  let dayArr = day.split('-')
  let newDay = null
  // 年相等
  if (newYear == dayArr[0]) {
    if (newMonth == dayArr[1]) {
      newDay = dayArr[2] + '日'
    } else {
      newDay = dayArr[1] + '月' + dayArr[2] + '日'
    }
  } else {
    newDay = dayArr[0] + '年' + dayArr[1] + '月' + dayArr[2] + '日'
  }
  return newDay
}
