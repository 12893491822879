<template>
  <div class="avatar-box">
    <el-avatar
      :size="size"
      :style="{ fontSize: font + 'px' }"
      :src="obj.avatar"
      icon="el-icon-user-solid"
      @error="errorHandler"
      class="comm icon-img"
      v-if="obj.avatar"
    ></el-avatar>

    <el-avatar :style="{ fontSize: font + 'px' }" class="user-img" :size="size" v-else>
      {{ obj.name ? obj.name.slice(-2) : '' }}
    </el-avatar>
  </div>
</template>

<script>
export default {
  name: 'user-avatar',
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    size: {
      type: [Number, String],
      default: 46
    },
    font: {
      type: [Number, String],
      default: 15
    }
  },
  computed: {
    avatar: {
      get() {
        return require('../../assets/images/profile.png')
      }
    }
  },
  filters: {
    formateVal(val) {
      return val.slice(-2)
    }
  },
  methods: {
    errorHandler() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-box {
  display: flex;
  align-items: center;
  cursor: pointer;
  .comm {
    box-sizing: border-box;
    cursor: pointer;
  }
  .user-img {
    background: $blue;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .icon-img {
    width: 100%;
    height: 100%;
  }
}
</style>
