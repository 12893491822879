import { String } from 'core-js'

export const DEMOCRACY_RATE_DATA = [
  {
    id: 'rate1',
    title: '工作成效',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate2',
    title: '快速响应',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate3',
    title: '主动配合',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate4',
    title: '乐于奉献',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate5',
    title: '团结和谐',
    rate: 0,
    desc: ''
  }
]

export const SATISFACTION_RATE_DATA = [
  {
    id: 'rate1',
    title: '服务价值',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate2',
    title: '合作态度',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate3',
    title: '响应速度',
    rate: 0,
    desc: ''
  },
  {
    id: 'rate4',
    title: '专业水平',
    rate: 0,
    desc: ''
  }
]

export const MineTabNames = [
  {
    name: 'received',
    label: '收到的评价'
  },
  {
    name: 'comment',
    label: '待我评价'
  },
  {
    name: 'complete',
    label: '我已评的'
  }
]

export const UnderlingsTabNames = [
  {
    name: 'myTeam',
    label: '我的团队'
  },
  {
    name: 'approve',
    label: '待我审批'
  },
  {
    name: 'alreadyApprove',
    label: '我已审批'
  }
]

export const SatisfactionUnderlingsTabNames = [
  {
    name: 'underlings',
    label: '团队满意度'
  },
  {
    name: 'approve',
    label: '待我审批'
  },
  {
    name: 'complaint',
    label: '投诉处理'
  },
  {
    name: 'touch',
    label: '感动处理'
  }
]

const inviterTitle = {
  automatic: {
    color: 'green',
    icon: 'el-icon-circle-check',
    title: '系统自动分配评价人',
    desc: '自动从以下成员中随机抽取成员按月评价'
  },
  assign: {
    color: 'green',
    icon: 'el-icon-circle-check',
    title: '管理员指定组织民主评议参评人',
    desc: '组织参评成员由管理员在后台指定'
  },
  auditStart: {
    color: 'black',
    title: '邀请以下人员按月评价你',
    desc: '须邀请与您有工作交集的领导或同事给你评价'
  },
  auditing: {
    color: 'black',
    title: '邀请评价人审批中',
    desc: '若长时间未审批，请及时联系审批人员'
  },
  auditReject: {
    color: 'red',
    title: '邀请评价人审批驳回',
    desc: '请联系审批人员核实原因并重新提交'
  },
  auditAutoReject: {
    color: 'red',
    title: '邀请评价人不足自动驳回',
    desc: '由于人员离职或其他异动导致原定的参评人员不足'
  },
  auditPass: {
    color: 'green',
    icon: 'el-icon-circle-check',
    title: '以下评价人审核已通过',
    desc: '若无组织人员变动，后续月份无须再次审核'
  }
}

export const InviterTitleComp = {
  name: 'inviter-title',

  functional: true,

  props: {
    schema: {
      require: true,
      type: String
    }
  },

  render: (h, { props }) => {
    const { color, icon, title, desc } = inviterTitle[props.schema]
    return (
      <h3 class={`inviter-title inviter-title--${color}`}>
        {icon ? <span class={`icon ${icon}`}></span> : ''}
        <p>{title}</p>
        <span class={`desc`}>{desc}</span>
      </h3>
    )
  }
}

const inviterState = {
  //待邀请
  awaitInvite: {
    color: 'orange',
    title: '待邀请',
    desc: '请在民主评议开始前完成邀请并提交审批人审批通过后生效，否则会影响你的本项维度得分'
  },
  //审批中
  auditing: {
    color: 'blue',
    title: '审批中',
    desc: '请跟进审批人及时完成审批，若在本轮民主评议开始后才完成审批的，将不能参与本轮民主评议'
  },
  //修改邀请
  amendInvite: {
    color: 'orange',
    title: '修改邀请',
    desc: '请在民主评议开始前完成修改并提交审批人审批通过后生效，否则系统依然使用现有的名单'
  },
  //审批通过
  auditPass: {
    color: 'green',
    icon: 'el-icon-circle-check',
    title: '审批通过',
    desc: '若无组织人员变动，后续月份无须再次审核'
  },
  //系统自动驳回
  auditAutoReject: {
    color: 'red',
    title: '系统自动驳回',
    desc: '因人员变更，你邀请的人员已少于最低评价人数要求，系统自动驳回，请修正后重新提交'
  },
  //管理员驳回
  auditReject: {
    color: 'red',
    title: '管理员驳回',
    desc: '管理员驳回驳回理由展示驳回理'
  },
  //已驳回
  yetReject: {
    color: 'red',
    title: '已驳回',
    desc: '驳回理由展示驳回理驳回理由展示驳'
  },
  //全部展示
  allInvite: {
    color: 'orange',
    // title: '待邀请',
    desc: '共参与个组织互评，若分组不正确，请联系BP在民主评议开始前完成修正'
  }
}

export const InviterTitleState = {
  // name: 'invite-title',
  functional: true,
  props: {
    inviteS: {
      require: true,
      type: String
    },
    data: {
      type: Object
    },
    state: {
      type: String
    }
  },
  render: (h, { props }) => {
    let { color, icon, title, desc } = inviterState[props.inviteS]
    const { data, inviteS, state, approveUserName } = props
    switch (inviteS) {
      case 'auditPass':
        desc = `应用于${data.approveTime}后开始的民主评议${data.approveRemark}`
        break
      case 'yetReject': //已驳回
        desc = `${data.approveRemark}`
        break
      case 'auditReject': //管理员驳回
        title = `管理员驳回(${data.rejectUserName})`
        desc = `${data.approveRemark}`
    }
    return (
      <div class={`inviter-title inviter-title--${color}`}>
        {state == 'invite' ? (
          <div class='invite-style'>
            {' '}
            <span class='invite-style-s'>{title}</span>
            <p class={!data?.approveRemark || `desc desc--${desc ? color : ''}`}>{data?.approveRemark || ''}</p>
          </div>
        ) : (
          <div class='extInvite'>
            {inviteS == 'auditPass' ? (
              <div class='passStyle'>
                <p class='ext-top'>
                  <span class='ext-top-s'>{title}</span>
                  <span>,应用于{data.approveTime}后开始的民主评议</span>
                </p>
                <p class={!data?.approveRemark || `desc desc--${data.approveRemark ? color : ''}`}>{data?.approveRemark || ''}</p>
              </div>
            ) : (
              <div>
                <p class='ext-top'>
                  <span class='ext-top-s'>{title}</span>
                  <span class='ext-approver'>审批人：{data.approveUserName}</span>
                </p>
                <p class={!data?.approveRemark || `desc desc--${desc ? color : ''}`}>{data?.approveRemark || ''}</p>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
