<template>
  <div class="person-selected">
    <div v-if="!show">
      <el-select
        v-model="currentValue"
        :multiple="multiple"
        :filterable="filterable"
        :disabled="disabled"
        :loading="loading"
        :clearable="clearable"
        :multipleLimit="multipleLimit"
        :remote="remote"
        :reserve-keyword="reserveKeyword"
        :remote-method="remoteMethod"
        :placeholder="placeholder"
        :value-key="propsAlias.value"
        @change="changePeople"
        popper-class="person-el-select"
        @focus.passive="dataList = []"
      >
        <el-option
          v-for="item in dataList"
          :key="item[propsAlias.value]"
          :label="item[propsAlias.label]"
          :value="multiple || isItemObj ? item : item[propsAlias.value]"
          :disabled="
            item[propsAlias.value]
              | hasDisable(disableDataList, propsAlias.value)
          "
        >
          <span class="label-left" v-html="item[propsAlias.label]"></span
          ><span class="label-right">{{ item.mainOrgChain || "" }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="tojoy-tag" v-if="show && multiple">
      <el-tag
        v-for="item in currentValue"
        :key="item[propsAlias.value]"
        type="info"
        effect="plain"
      >
        {{ item[propsAlias.label] }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import {
  reqGetEmpListByIds,
  reqGetEmpListByName,
} from "../../../api/api_public";
import { isEmptyObject } from "../../../utils";

export default {
  name: "person-selected",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isItemObj: {
      type: Boolean,
      default: false,
    },
    value: {
      require: true,
    },
    // 数据项属性别名
    propsAlias: {
      type: Object,
      default: () => {
        return {
          value: "userId",
          label: "userName",
        };
      },
    },
    remote: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    multiple: {
      // 单多选
      type: Boolean,
      default: true,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return "请选择邀请人";
      },
    },
    reserveKeyword: {
      type: Boolean,
      default: true,
    },
    disableDataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {},
  filters: {
    hasDisable: function(val, dataList, key) {
      let result = dataList.findIndex((k) => {
        return k[key] == val;
      });
      return result > -1 ? true : false;
    },
  },
  data() {
    return {
      loading: false,
      first: true,
      query: "",
      allDataList: [],
      dataList: [],
      currentValue: [],
    };
  },
  watch: {
    value(newVal) {
      if (this.query || this.first) {
        this.first = false;
        this.initData(newVal);
      }
      this.currentValue = newVal;
    },
  },
  async created() {
    if (!isEmptyObject(this.value) || (this.value && this.value.length)) {
      this.first = false;
      await this.initData(this.value);
    }
    this.currentValue = this.value;
  },
  methods: {
    // 初始化回显
    async initData(value) {
      if (!value || value.length === 0 || isEmptyObject(value)) {
        return false;
      }
      this.dataList = value;
      // let Ids = []
      // if (value.length && value.length > 0) {
      //   value.forEach(async k => {
      //     Ids.push(k[this.propsAlias.value])
      //   })
      // }
      // if (!isEmptyObject(value)) {
      //   Ids.push(value[this.propsAlias.value])
      // } else {
      //   Ids.push(value)
      // }
      // await this.getListByIds(Ids)
    },
    // // 根据ids查询任务列表
    // getListByIds(ids) {
    //   return reqGetEmpListByIds({ idList: ids, type:2 }).then(res => {
    //     this.dataList = res.data.userList
    //   })
    // },
    // 回传数据
    changePeople(data) {
      this.dataList = [];
      this.query = "";
      this.first = false;
      this.$emit("input", data);
    },
    // 搜索
    remoteMethod(query) {
      this.query = query;
      if (query !== "") {
        this.loading = true;
        reqGetEmpListByName({ name: query || "", isAccess: 0 }).then((res) => {
          this.loading = false;
          const personList = res.data.userList;
          this.dataList = personList;
        });
      } else {
        this.dataList = [];
      }
    },
  },
};
</script>

<style lang="scss">
.person-selected {
  .el-form-item {
    margin-bottom: 10px;
    &__label {
      padding-bottom: 0;
    }
    &__error {
      padding-left: 10px;
    }
  }
  .el-select {
    width: 100%;
    &__tags {
      .el-tag {
        margin-left: 0px;
        margin-right: 10px;
        margin-bottom: 10px;
        background: $white;
        border-radius: 17px;
        padding: 0 17px;
        font-size: 12px;
        font-weight: 400;
        color: $lightblack;
        &--small {
          height: 30px;
          line-height: 30px;
        }
      }
    }
    .el-input__inner {
      border-radius: 0;
      border-width: 0 0 1px 0;
      border-color: $linecolor;
      padding: 0 10px;
    }
  }
  .tojoy-tag {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 10px;
      background: #ffffff;
      border-radius: 17px;
      padding: 0 17px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .el-input .el-input__inner {
      background: none;
      border-bottom: 0px solid $linecolor;
    }
  }
  .el-select__tags .el-tag:hover {
    border-color: #3d7eff !important;
  }
  .el-tag.el-tag--info .el-tag__close:hover {
    color: #ffffff !important;
    background-color: #3d7eff !important;
  }
}
.person-el-select {
  .label-left {
    display: inline-block;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
  .label-right {
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}
.el-select-dropdown__item {
  display: flex;
  align-items: center;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  .label-left {
    color: #3d7eff;
  }
  .label-right {
    color: #3d7eff;
  }
}
</style>
