import Vue from 'vue'
import '@/plugins/index'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/main.scss'
import { Message as ElMessage } from 'element-ui'
import '@/directives/index.js'
import '@/filters/index.js'
import {
  filterParamsTrim,
  filterTrim,
  checkTrim,
  getFileSuffix,
  splitFileName,
  scoreSum
} from '@/utils/index'
import { Object } from 'core-js'
Vue.config.productionTip = false
Vue.prototype.$msgSuccess = function(msg) {
  ElMessage({
    showClose: true,
    message: msg || '成功',
    type: 'success'
  })
}
Vue.prototype.$msgError = function(msg) {
  ElMessage({
    showClose: true,
    message: msg || '错误',
    type: 'error'
  })
}

Vue.prototype.$warning = function(msg) {
  ElMessage({
    showClose: true,
    message: msg || '',
    type: 'warning'
  })
}
let utilFn = {
  filterParamsTrim,
  filterTrim,
  checkTrim,
  getFileSuffix,
  splitFileName,
  scoreSum
}
for (let key in utilFn) {
  Vue.prototype['$' + key] = utilFn[key]
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
